/* eslint-disable */

import Script from 'next/script';
import { Fragment } from 'react';

export const GoogleTagManager = () => (
  <Fragment>
    <Script
      strategy="afterInteractive"
      src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GTM_CODE}`}
    />
    <Script strategy="afterInteractive" id="google-analytics">
      {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', '${process.env.NEXT_PUBLIC_GTM_CODE}');
        `}
    </Script>
  </Fragment>
);

export const Cookiebot = () => (
  <Script
    id="Cookiebot"
    src="https://consent.cookiebot.com/uc.js"
    strategy="afterInteractive"
    data-cbid="598835e7-8367-42fc-9c2b-376ee0029da1"
    type="text/javascript"
    async
  ></Script>
);

export const CookiebotDeclaration = () => (
  <Script
    id="CookieDeclaration"
    strategy="afterInteractive"
    src="https://consent.cookiebot.com/598835e7-8367-42fc-9c2b-376ee0029da1/cd.js"
    type="text/javascript"
    async
  ></Script>
);
