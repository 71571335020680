import { useCallbackRef } from '@chakra-ui/react';
import { getModelType } from 'datx';
import { prepareQuery, Response } from 'datx-jsonapi';
import isFunction from 'lodash/isFunction';
import useSWR from 'swr';

import { useStores } from '@/hooks/useStores';

import { Resource } from './Resource';
import { Meta, QueryConfig, QueryResource, QueryResources } from './types';
import { pickRequestOptions, loadAll as loadAllData } from './utils';

// eslint-disable-next-line @typescript-eslint/ban-types
export function useResource<TModel extends Resource = Resource, TMeta extends Meta = Meta>(
  queryResource: QueryResource<TModel>,
  config?: QueryConfig<TModel>
) {
  const { store } = useStores();

  const getKey = () => {
    const [type, id, options] = isFunction(queryResource) ? queryResource() : queryResource;
    const modelType = getModelType(type);

    const query = prepareQuery(modelType, id, undefined, options);

    return query.url;
  };

  const fetcher = async (url: string) => {
    // TODO: this is suboptimal because we are doing the same thing in getKey
    const [_, __, options] = isFunction(queryResource) ? queryResource() : queryResource;

    const requestOptions = pickRequestOptions(options);

    const response = await store.request<TModel>(url, 'GET', null, requestOptions);

    if (config?.sideload) {
      await config.sideload(response);
    }

    return response;
  };

  const swr = useSWR<Response<TModel>, Response<TModel>>(getKey, fetcher, config);

  // TODO: implement data select with getters
  if (config?.select) {
    throw Error('Select is not implemented');
  }

  return {
    mutate: swr.mutate,
    get data() {
      return swr.data?.data as TModel;
    },
    get error() {
      if (swr.error instanceof Response) {
        return swr.error.error;
      }

      return swr.error;
    },
    get isValidating() {
      return swr.isValidating;
    },
    get meta() {
      return swr.data?.meta as TMeta;
    },
  };
}

export function useResources<TModel extends Resource = Resource, TMeta extends Meta = Meta>(
  queryResources: QueryResources<TModel>,
  config?: QueryConfig<TModel>
) {
  const { store } = useStores();

  const getKey = () => {
    const [type, options] = isFunction(queryResources) ? queryResources() : queryResources;
    const modelType = getModelType(type);

    const query = prepareQuery(modelType, undefined, undefined, options);

    if (options?.loadAll) {
      return [query.url, 'loadAll'];
    }

    return query.url;
  };

  const fetcher = async (url: string, loadAll: string) => {
    // TODO: this is suboptimal because we are doing the same thing in getKey
    const [_, options] = isFunction(queryResources) ? queryResources() : queryResources;

    const requestOptions = pickRequestOptions(options);

    const response = await store.request<TModel>(url, 'GET', null, requestOptions);

    if (loadAll) {
      return loadAllData(response);
    }

    if (config?.sideload) {
      await config.sideload(response);
    }

    return response;
  };

  const swr = useSWR<Response<TModel>, Response<TModel>>(getKey, fetcher, config);

  const nextPage = useCallbackRef(() => {
    if (swr.data?.links?.next) {
      swr.mutate(swr.data?.next, false);
    }
  });

  return {
    mutate: swr.mutate,
    get data(): Array<TModel> {
      // Remove this if it's not used
      if (config?.select && swr.data) {
        // @ts-ignore
        return config.select(swr.data);
      }

      return swr.data?.data as Array<TModel>;
    },
    get error() {
      if (swr.error instanceof Response) {
        return swr.error.error;
      }
      return swr.error;
    },
    get isValidating() {
      return swr.isValidating;
    },
    get meta() {
      return swr.data?.meta as TMeta;
    },
    nextPage,
    get hasNextPage() {
      return Boolean(swr.data?.links?.next);
    },
  };
}
