import { prop } from 'datx';
import { IResponse } from 'datx-jsonapi/dist/interfaces/JsonApi';

import { Resource } from '@/lib/datx-jsonapi-react';
import { ThinProfile } from '@/resources/ThinProfile';

import { User } from './User';

export class Session extends Resource {
  public static readonly type = 'sessions';

  @prop.toOne('users')
  public user: User;

  @prop.toOne('thin_profiles')
  public mainProfile: ThinProfile;
}

export type SessionDocument = Omit<IResponse, 'type'> & { data: { type: typeof Session['type'] } };
