import { prop } from 'datx';
import { Resource } from 'datx-jsonapi-react';

import { MaterialType } from '@/enums/MaterialType';

export class OriginalsMaterial extends Resource {
  public static type = 'originals/series_materials';

  @prop
  public name: string;

  @prop
  public position: string;

  @prop
  public materialType: MaterialType;

  @prop
  public fileLink: string;

  @prop
  public fileUrl: string;
}
