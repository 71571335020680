import { prop } from 'datx';

import { BaseModel } from './BaseModel';

export class NewsArticleTopic extends BaseModel {
  public static type = 'news_article_topics';

  @prop
  public name: string;
}
