import { prop } from 'datx';
import { Resource } from 'datx-jsonapi-react';

export type MenuTabType = 'lectures' | 'posters' | 'speakers';

export class MenuTab extends Resource {
  public static type = 'menu_tabs';

  @prop
  public name: string;

  @prop
  public type: MenuTabType;
}
