const baseStyle = {
  track: {
    _checked: {
      bg: 'violet.500',
    },
  },
};

function narrowTrack(props: Record<string, any>) {
  return {
    thumb: {
      bg: 'violet.500',
      w: '20px',
      h: '20px',
      position: 'absolute',
      top: '-3px',
      left: '-1px',
      _checked: {
        transform: 'translateX(20px)',
      },
    },
    track: {
      mt: '4px',
      h: '10px',
      w: '36px',
      bg: 'gray.200',
      position: 'relative',
      _checked: {
        bg: 'gray.200',
      },
    },
  };
}

const variants = {
  narrowTrack,
};

export default {
  baseStyle,
  variants,
};
