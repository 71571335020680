import { useCallbackRef } from '@chakra-ui/react';
import { NextRouter, useRouter } from 'next/router';
import { useState } from 'react';

import { updateNextUrlQueryParams } from '@/utils/redirect';
import { ParamTypes, urlQueryStringValidate } from '@/utils/url-query-string';
export { StringParam, ArrayLinkSlugParam, StatusStringParam, SlugIdParam } from '@/utils/url-query-string';

export type ChangeMethod = 'replace' | 'push';

type TransitionOptions = Parameters<NextRouter['replace']>[2];

interface IChangeOptions extends Pick<TransitionOptions, 'shallow' | 'scroll'> {
  method?: ChangeMethod;
}

const defaultChangeOptions: IChangeOptions = {
  method: 'replace',
};

/**
 * @example
 *  import { StringParam } from '@/utils/url-query-string';
 *  import { useQueryParams } from '@/hooks/useQueryParams';
 *
 *  interface IUrlQueryStringParams {
 *    eventId?: string;
 *  }
 *
 *  const { eventId, change } = useQueryParams<IUrlQueryStringParams>({ eventId: StringParam });
 *
 * change({ eventId: '1' }, { shallow: true, method: 'replace' });
 */
export function useQueryParams<T>(paramTypes: ParamTypes = {}) {
  const router = useRouter();
  const [types] = useState(() => paramTypes); // this will make it constant

  const validatedQueryParams = urlQueryStringValidate<T>(router?.query, types);

  const change = useCallbackRef((query: T, options: IChangeOptions) => {
    const { method, shallow, scroll } = { ...defaultChangeOptions, ...options };
    const { url, as } = updateNextUrlQueryParams(router, query);

    const urlChangeFn = router[method] as NextRouter['replace'];

    return urlChangeFn(url, as, { shallow, scroll });
  }, []);

  return {
    ...validatedQueryParams,
    change,
    router,
  };
}
