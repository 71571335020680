import { prop } from 'datx';
import { Resource } from 'datx-jsonapi-react';

import { PackageType } from '@/enums/Originals';

export class OriginalsEpisode extends Resource {
  public static type = 'originals/episodes';

  @prop
  public title: string;

  @prop
  public description: string;

  @prop
  public slug: string;

  @prop
  public duration: string;

  @prop
  public position: string;

  @prop
  public readingsUrl: string;

  @prop
  public notesUrl: string;

  @prop
  public originalThumbnailUrl: string;

  @prop
  public largeThumbnailUrl: string;

  @prop
  public mediumThumbnailUrl: string;

  @prop
  public smallThumbnailUrl: string;

  @prop
  public thumbnailThumbnailUrl: string;

  @prop
  public preloaderThumbnailUrl: string;

  @prop
  public package: PackageType;
}
