import { prop } from 'datx';

import { BaseModel } from './BaseModel';

export class Track extends BaseModel {
  public static type = 'tracks';

  @prop
  public name: string;

  @prop
  public main: boolean;

  @prop
  public backgroundColor: string;

  @prop
  public textColor: string;

  @prop
  public position: number;

  @prop
  public ancestry: string;
}
