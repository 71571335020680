import { useEffect } from 'react';

import { IToastType } from '@/enums/ToastType';
import { StringParam, useQueryParams } from '@/hooks/useQueryParams';
import { useToast } from '@/hooks/useToast';

export const GoogleLoginHandler = () => {
  const { login_provider, change } = useQueryParams<{ login_provider?: string }>({
    login_provider: StringParam,
  });
  const toast = useToast();

  useEffect(() => {
    if (login_provider === 'google') {
      toast({ status: IToastType.SUCCESS, title: 'Successfully logged in' });
      change({ login_provider: undefined }, { shallow: true, method: 'replace' });
    }
  }, [login_provider, toast]);

  return null;
};
