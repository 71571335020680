import { prop } from 'datx';
import { Resource } from 'datx-jsonapi-react';

export class ThinConference extends Resource {
  public static type = 'thin_conferences';

  @prop
  public abbreviation: string;

  @prop
  public name: string;

  @prop
  public extendedName: string;

  @prop
  public site: string;

  @prop
  public description: string;

  @prop
  public lecturesCount: number;

  @prop
  public speakersCount: number;

  @prop
  public subjectsCount: number;

  @prop
  public comingSoon: number;

  @prop
  public originalHeaderImageUrl: string;

  @prop
  public largeHeaderImageUrl: string; // 1200x1200

  @prop
  public mediumHeaderImageUrl: string; // 600x600

  @prop
  public smallHeaderImageUrl: string; // 300x300

  @prop
  public thumbnailHeaderImageUrl: string; // 48x48

  @prop
  public preloaderHeaderImageUrl: string; // 8x8

  @prop
  public originalLogoUrl: string;

  @prop
  public smallLogoUrl: string;

  @prop
  public mediumLogoUrl: string;

  @prop
  public largeLogoUrl: string;

  @prop
  public thumbnailLogoUrl: string;

  @prop
  public preloaderLogoUrl: string;

  @prop
  public slug: string;

  @prop
  public facebook: string;

  @prop
  public linkedin: string;

  @prop
  public email: string;

  @prop
  public twitter: string;

  @prop
  public additionalInformation: string;

  @prop
  public visibleLibraryEventsCount: string;

  @prop
  public authorsSpeakersCount: string;

  @prop
  public shortDescription: string;
}
