import isFunction from 'lodash/isFunction';

import { useResource } from '../hooks';
import { ExtractModel, ResponseData } from '../interfaces/ListResponse';
import { Resource } from '../Resource';
import { Meta, QueryRelationshipConfig, QueryResourceRelationship } from '../types';

type UseResourceRelationship<TUseResource, TData, TMeta> = TUseResource extends (...args) => infer T
  ? (...args) => T extends { data: any; meta: any } ? Omit<T, 'data' | 'meta'> & { data: TData; meta: TMeta } : never
  : never;

export function useResourceRelationship<TData extends ResponseData = Resource, TMeta extends Meta = Meta>(
  queryResource: QueryResourceRelationship<ExtractModel<TData>>,
  config?: QueryRelationshipConfig<TData>
) {
  const query = () => {
    const [type, id, relationship, options] = isFunction(queryResource) ? queryResource() : queryResource;

    return [type, `${id}/${relationship}`, options];
  };

  return ((useResource as unknown) as UseResourceRelationship<typeof useResource, TData, TMeta>)(query, config);
}
