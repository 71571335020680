import { prop } from 'datx';
import { computed } from 'mobx';

import { BaseModel } from './BaseModel';
import { ScheduleEntry } from './ScheduleEntry';

export class Livestream extends BaseModel {
  public static type = 'livestreams';

  @prop
  public title: string;

  @prop
  public scheduledAt: string;

  @prop
  public published: boolean;

  @prop
  public url: string;

  @prop
  public description: string;

  @prop
  public chatId: string;

  @prop
  public videoId: string;

  @prop
  public agenda: string;

  @prop
  public twilioQuestionsChannel: string;

  @prop
  public secondaryHlsUrl?: string;

  /**
   * @deprecated Remove if it's not used
   */
  @prop.toMany(ScheduleEntry)
  public scheduleEntries: Array<ScheduleEntry>;

  @computed
  public get isActive() {
    return (this.published && new Date(this.scheduledAt)) < new Date();
  }
}
