import { useToken } from '@chakra-ui/react';
import { Global, css } from '@emotion/react';
import { FC } from 'react';

export const GlobalStyles: FC = () => {
  const [dimmed] = useToken('colors', ['gray.50']);

  return (
    <Global
      styles={css`
        html {
          scroll-behavior: smooth;
        }

        body {
          background-color: ${dimmed};
        }

        @font-face {
          font-family: 'IBM Plex Serif';
          font-weight: 400;
          font-style: normal;
          font-display: swap;
          src: url('/fonts/IBMPlexSerif/IBMPlexSerif-Regular.woff') format('woff');
        }

        @font-face {
          font-family: 'IBM Plex Serif';
          font-weight: 400;
          font-style: italic;
          font-display: swap;
          src: url('/fonts/IBMPlexSerif/IBMPlexSerif-Italic.woff') format('woff');
        }

        @font-face {
          font-family: 'IBM Plex Sans';
          font-weight: 400;
          font-style: normal;
          font-display: swap;
          src: url('/fonts/IBMPlexSans/IBMPlexSans-Regular.woff') format('woff');
        }

        @font-face {
          font-family: 'IBM Plex Sans';
          font-weight: 500;
          font-style: normal;
          font-display: swap;
          src: url('/fonts/IBMPlexSans/IBMPlexSans-Medium.woff') format('woff');
        }

        @font-face {
          font-family: 'Space Grotesk';
          font-weight: 400;
          font-style: normal;
          font-display: swap;
          src: url('/fonts/Space-Grotesk/SpaceGrotesk-Regular.woff') format('woff');
        }

        @font-face {
          font-family: 'Space Grotesk Underline';
          font-weight: 400;
          font-style: normal;
          font-display: swap;
          src: url('/fonts/Space-Grotesk-underline/SpaceGroteskUnderline-Regular.woff') format('woff');
        }

        @font-face {
          font-family: 'Space Grotesk Underline';
          font-weight: 600;
          font-style: normal;
          font-display: swap;
          src: url('/fonts/Space-Grotesk-underline/SpaceGroteskUnderline-SemiBold.woff') format('woff');
        }

        @font-face {
          font-family: 'Space Grotesk Underline';
          font-weight: 600;
          font-style: normal;
          font-display: swap;
          src: url('/fonts/Space-Grotesk-underline/SpaceGroteskUnderline-SemiBold.woff') format('woff');
        }

        @font-face {
          font-family: 'Space Grotesk Underline';
          font-weight: 700;
          font-style: normal;
          font-display: swap;
          src: url('/fonts/Space-Grotesk-underline/SpaceGroteskUnderline-Bold.woff') format('woff');
        }

        @font-face {
          font-family: 'Space Grotesk';
          font-weight: 600;
          font-style: normal;
          font-display: swap;
          src: url('/fonts/Space-Grotesk/SpaceGrotesk-SemiBold.woff') format('woff');
        }

        @font-face {
          font-family: 'Space Grotesk';
          font-weight: 700;
          font-style: normal;
          font-display: swap;
          src: url('/fonts/Space-Grotesk/SpaceGrotesk-Bold.woff') format('woff');
        }

        @font-face {
          font-family: 'Mada';
          font-weight: 400;
          font-style: normal;
          font-display: swap;
          src: url('/fonts/Mada/Mada-Regular.woff') format('woff');
        }

        @font-face {
          font-family: 'Mada';
          font-weight: 700;
          font-style: normal;
          font-display: swap;
          src: url('/fonts/Inter/Mada-Bold.woff') format('woff');
        }

        @font-face {
          font-family: 'Noto Sans TC';
          font-weight: 400;
          font-style: normal;
          font-display: swap;
          src: url('/fonts/NotoSansTC/NotoSansTC-Regular.woff') format('woff');
        }

        @font-face {
          font-family: 'Noto Sans TC';
          font-weight: 700;
          font-style: normal;
          font-display: swap;
          src: url('/fonts/NotoSansTC/NotoSansTC-Bold.woff') format('woff');
        }
      `}
    />
  );
};
