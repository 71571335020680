import { cardAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, cssVar } from '@chakra-ui/styled-system';

const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(parts.keys);

const $bg = cssVar('card-bg');
const $padding = cssVar('card-padding');
const $shadow = cssVar('card-shadow');
const $radius = cssVar('card-radius');
const $border = cssVar('card-border-width', '0');
const $borderColor = cssVar('card-border-color');

const baseStyle = definePartsStyle({
  container: {
    [$bg.variable]: 'colors.white',
  },
});

const sizes = {
  md: definePartsStyle({
    container: {
      [$radius.variable]: 'radii.md',
      [$padding.variable]: 'space.6',
    },
  }),
};

const variants = {
  glass: definePartsStyle({
    container: {
      [$border.variable]: '1px',
      [$borderColor.variable]: 'colors.white',
      bg: 'linear-gradient(124.9deg, rgba(239, 239, 239, 0.6) 0%, rgba(255, 255, 255, 0.1) 100%)',
      backdropFilter: `blur(33px)`,
    },
  }),

  elevated: definePartsStyle({
    container: {
      [$bg.variable]: `colors.white`,
      [$border.variable]: '1px',
      [$borderColor.variable]: 'colors.white',
      [$shadow.variable]: `0px 8px 32px rgba(0, 0, 0, 0.08)`,
    },
  }),

  filled: definePartsStyle({
    container: {
      [$bg.variable]: `colors.white`,
    },
  }),

  outline: definePartsStyle({
    container: {
      [$bg.variable]: 'colors.transparent',
      [$border.variable]: '1px',
      [$borderColor.variable]: 'colors.gray.100',
    },
  }),
};

export default defineMultiStyleConfig({
  baseStyle,
  variants,
  sizes,
});
