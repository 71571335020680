import { prop } from 'datx';
import { Resource } from 'datx-jsonapi-react';

import { OriginalsEpisode } from '@/resources/OriginalsEpisode';
import { TranscriptLanguage } from '@/resources/TranscriptLanguage';

export class OriginalsEpisodeTranscripts extends Resource {
  public static type = 'originals/episode_transcripts';

  @prop
  public subtitleUrl: string;

  @prop
  public autoGenerated: boolean;

  @prop
  public pdfUrl: string;

  @prop.toOne('originals/episodes')
  public subject: OriginalsEpisode;

  @prop.toOne('transcript_languages')
  public language: TranscriptLanguage;
}
