import { prop } from 'datx';
import { Resource } from 'datx-jsonapi-react';
import { computed } from 'mobx';

import { LectureAccessPermissions } from '@/resources/LectureAccessPermissions';
import { Tag } from '@/resources/Tag';
import { ThinEventSession } from '@/resources/ThinEventSession';
import { ThinProfile } from '@/resources/ThinProfile';
import { PackageType } from '@/types/PackageType';

export class OmnisearchThinLecture extends Resource {
  public static type = 'omnisearch/thin_lectures';

  @prop
  public title: string;

  @prop
  public synopsis: string;

  @prop
  public abstract: string;

  @prop
  public heldAt?: string;

  @prop
  public publishedAt?: string;

  @prop
  public slug?: string;

  @prop
  public posterLecture: boolean;

  @prop
  public package: PackageType;

  @prop
  public eventId: string;

  @prop
  public library?: boolean;

  @prop
  public eventName?: string;

  @prop
  public published?: boolean;

  @prop
  public playlist: string;

  @prop
  public slideshow: string;

  @prop
  public posterDocument: string;

  @prop
  public paper: string;

  @prop
  public mediumPosterDocumentThumbnailUrl: string;

  @prop
  public mediumPosterUrl: string;

  @prop
  public mediumPosterDocumentThumbnailExtractUrl: string;

  @prop
  public originalPosterUrl: string;

  @prop
  public largePosterUrl: string;

  @computed
  public get accessRestricted() {
    // eslint-disable-next-line prefer-destructuring
    const collection = this.meta?.collection;

    if (collection) {
      return collection.findOne(LectureAccessPermissions, this.id)?.restricted;
    }

    return null;
  }

  @computed
  public get posterThumbnailUrl() {
    return this.posterLecture
      ? this.mediumPosterDocumentThumbnailUrl || this.mediumPosterDocumentThumbnailExtractUrl
      : this.mediumPosterUrl;
  }

  @prop.toMany('thin_profiles')
  public sortedProfiles: Array<ThinProfile>;

  @prop.toOne('tags')
  public tag: Tag;

  @prop.toOne('thin_event_sessions')
  public eventSession: ThinEventSession;
}
