/* eslint-disable no-underscore-dangle */
import { Collection, getModelType, IModelConstructor, IType } from 'datx';
import { IRequestOptions, jsonapi, Response } from 'datx-jsonapi';
import isFunction from 'lodash/isFunction';

import { QueryResource, QueryResources } from '@/lib/datx-jsonapi-react';
import { IFetchConfig } from '@/lib/datx-jsonapi-react/interfaces/ClientConfig';
import {
  QueryInfiniteSideload,
  QueryResourceRelationship,
  QueryResourcesInfinite,
} from '@/lib/datx-jsonapi-react/types';
import { getRawResponse, undefinedToNull } from '@/lib/datx-jsonapi-react/utils';

import { Resource } from './Resource';

const INFINITE_PREFIX = '$inf$';

export class Client extends jsonapi(Collection) {
  private readonly _fallback = new Map();
  /**
   *
   * @param {string} type Record type
   * @param {number|string} type Record id
   * @param {IRequestOptions} [options] Server options
   */

  public get fallback() {
    return Object.fromEntries(this._fallback);
  }

  public queryResource<TModel extends Resource = Resource>(
    type: IType | IModelConstructor<TModel>,
    id: number | string,
    options?: IRequestOptions
  ) {
    const modelType = getModelType(type);
    // @ts-ignore
    const query = this.__prepareQuery(modelType, id, undefined, options);

    const fetcher = () => this.fetch<TModel>(type, id, options);

    return {
      key: query.url,
      fetcher,
    };
  }

  /**
   * @param {string} type Record type
   * @param {IRequestOptions} [options] Server options
   */
  public queryResources<TModel extends Resource = Resource>(
    type: IType | IModelConstructor<TModel>,
    options?: IRequestOptions
  ) {
    const modelType = getModelType(type);
    // @ts-ignore
    const query = this.__prepareQuery(modelType, undefined, undefined, options);

    const fetcher = () => this.fetchAll<TModel>(type, options);

    return {
      key: query.url,
      fetcher,
    };
  }

  /**
   * @deprecated - use fetchResources
   */
  public async fetchQuery<TModel extends Resource = Resource>(queryResources: QueryResources<TModel>) {
    const [type, options] = isFunction(queryResources) ? queryResources() : queryResources;
    const { key, fetcher } = this.queryResources(type, options);

    const response = await fetcher();

    const data = undefinedToNull(getRawResponse(response).data);

    return {
      key,
      data,
    };
  }

  public async fetchResource<TModel extends Resource = Resource>(
    queryResource: QueryResource<TModel>,
    config?: IFetchConfig<TModel>
  ) {
    const [type, id, options] = isFunction(queryResource) ? queryResource() : queryResource;

    const { key, fetcher } = this.queryResource(type, id, options);

    let response;

    try {
      response = await fetcher();
    } catch (err) {
      const shouldNotThrow = config?.dontThrowOnError?.(err);

      if (!shouldNotThrow) throw err;
    }

    const rawData = undefinedToNull(getRawResponse(response)?.data);

    this._fallback.set(key, rawData);

    return response;
  }

  public async fetchResourceRelationship<TModel extends Resource = Resource>(
    queryResource: QueryResourceRelationship<TModel>,
    config?: IFetchConfig<TModel>
  ) {
    const [type, id, relationship, options] = isFunction(queryResource) ? queryResource() : queryResource;

    const { key, fetcher } = this.queryResource(type, `${id}/${relationship}`, options);

    let response;

    try {
      response = await fetcher();
    } catch (err) {
      const shouldNotThrow = config?.dontThrowOnError?.(err);

      if (!shouldNotThrow) throw err;
    }
    const rawData = undefinedToNull(getRawResponse(response)?.data);

    this._fallback.set(key, rawData);

    return response;
  }

  public async fetchResources<TModel extends Resource = Resource>(
    queryResources: QueryResources<TModel>,
    config?: IFetchConfig<TModel>
  ) {
    const [type, options] = isFunction(queryResources) ? queryResources() : queryResources;
    const { key, fetcher } = this.queryResources(type, options);

    let response;

    try {
      response = await fetcher();
    } catch (err) {
      const shouldNotThrow = config?.dontThrowOnError?.(err);

      if (!shouldNotThrow) throw err;
    }

    const rawData = undefinedToNull(getRawResponse(response)?.data);

    this._fallback.set(key, rawData);

    return response;
  }

  public async fetchResourcesInfinite<TModel extends Resource = Resource>(
    queryResourcesInfinite: QueryResourcesInfinite<TModel>,
    config?: QueryInfiniteSideload<TModel>
  ) {
    const resourceInfiniteResult = queryResourcesInfinite(0, null);

    if (Array.isArray(resourceInfiniteResult)) {
      const [type, options] = resourceInfiniteResult;

      const { key, fetcher } = this.queryResources(type, options);

      const response = await fetcher();

      let rawSideloadData;

      if (config?.sideload) {
        const sideloadResponse = (await config?.sideload(response)) as Response<TModel>;

        rawSideloadData = getRawResponse(sideloadResponse).data;
      }

      const rawData = getRawResponse(response).data;

      const data = undefinedToNull({ rawData, rawSideloadData });

      this._fallback.set(`${INFINITE_PREFIX}${key}`, [data]);

      return response;
    }

    return resourceInfiniteResult;
  }
}
