export enum ButtonTheme {
  DARK = 'dark',
  LIGHT = 'light',
}

export enum ButtonVariant {
  FILLED = 'filled',
  DARK_FILLED = 'dark filled',
  SIGNAL_FILLED = 'signal filled',
  ACTION_FILLED = 'action filled',
  HOLLOW = 'hollow',
  GLASS = 'glass',
  GHOST = 'ghost',
}

export enum ButtonState {
  REGULAR = 'regular',
  HOVER = 'hover',
  ACTIVE = 'active',
  DISABLED = 'disabled',
}

type ButtonVariantConfg = Record<ButtonVariant, ButtonStateConfig>;
type ButtonStateConfig = Record<ButtonState, { color: string; background: string; borderColor?: string }>;

/**
 * @deprecated - use Chakra UI Button
 */
export const buttonTheme: Record<ButtonTheme, ButtonVariantConfg> = {
  [ButtonTheme.LIGHT]: {
    [ButtonVariant.FILLED]: {
      [ButtonState.REGULAR]: {
        color: 'white',
        background: '#5B1CF4',
      },
      [ButtonState.HOVER]: {
        color: 'white',
        background: '#5B1CF4',
      },
      [ButtonState.ACTIVE]: {
        color: 'white',
        background: '#5B1CF4',
      },
      [ButtonState.DISABLED]: {
        color: 'white',
        background: '#808080',
      },
    },
    [ButtonVariant.DARK_FILLED]: {
      [ButtonState.REGULAR]: {
        color: 'white',
        background: 'black',
      },
      [ButtonState.HOVER]: {
        color: 'white',
        background: 'black',
      },
      [ButtonState.ACTIVE]: {
        color: 'white',
        background: '#3F3F3F',
      },
      [ButtonState.DISABLED]: {
        color: 'white',
        background: '#808080',
      },
    },
    [ButtonVariant.SIGNAL_FILLED]: {
      [ButtonState.REGULAR]: {
        color: 'white',
        background: '#EF5085',
      },
      [ButtonState.HOVER]: {
        color: 'white',
        background: '#EF5085',
      },
      [ButtonState.ACTIVE]: {
        color: 'white',
        background: '#CC3567',
      },
      [ButtonState.DISABLED]: {
        color: 'white',
        background: '#808080',
      },
    },
    [ButtonVariant.ACTION_FILLED]: {
      [ButtonState.REGULAR]: {
        color: 'black',
        background: '#B6FFE2',
      },
      [ButtonState.HOVER]: {
        color: 'black',
        background: '#B6FFE2',
      },
      [ButtonState.ACTIVE]: {
        color: 'black',
        background: '#19A68F',
      },
      [ButtonState.DISABLED]: {
        color: 'white',
        background: '#808080',
      },
    },
    [ButtonVariant.HOLLOW]: {
      [ButtonState.REGULAR]: {
        color: 'black',
        background: 'none',
        borderColor: '#ccc',
      },
      [ButtonState.HOVER]: {
        color: 'black',
        background: 'none',
        borderColor: '#ccc',
      },
      [ButtonState.ACTIVE]: {
        color: 'white',
        background: '#5B1CF4',
        borderColor: '#ccc',
      },
      [ButtonState.DISABLED]: {
        color: '#808080',
        background: 'none',
        borderColor: '#ccc',
      },
    },
    [ButtonVariant.GLASS]: {
      [ButtonState.REGULAR]: {
        color: 'black',
        background: 'rgba(173, 216, 208, 0.27);',
      },
      [ButtonState.HOVER]: {
        color: 'black',
        background: 'rgba(173, 216, 208, 0.27);',
      },
      [ButtonState.ACTIVE]: {
        color: 'white',
        background: '#19A68F',
      },
      [ButtonState.DISABLED]: {
        color: 'black',
        background: 'rgba(216, 216, 216, 0.27);',
      },
    },
    [ButtonVariant.GHOST]: {
      [ButtonState.REGULAR]: {
        color: 'black',
        background: 'none',
      },
      [ButtonState.HOVER]: {
        color: 'black',
        background: 'none',
      },
      [ButtonState.ACTIVE]: {
        color: 'black',
        background: 'none',
      },
      [ButtonState.DISABLED]: {
        color: '#808080',
        background: 'none',
      },
    },
  },
  [ButtonTheme.DARK]: {
    [ButtonVariant.FILLED]: {
      [ButtonState.REGULAR]: {
        color: 'black',
        background: '#AA8AF7',
      },
      [ButtonState.HOVER]: {
        color: 'black',
        background: '#AA8AF7',
      },
      [ButtonState.ACTIVE]: {
        color: 'black',
        background: '#AA8AF7',
      },
      [ButtonState.DISABLED]: {
        color: 'black',
        background: '#B4B4B4',
      },
    },
    [ButtonVariant.DARK_FILLED]: {
      [ButtonState.REGULAR]: {
        color: 'white',
        background: 'black',
      },
      [ButtonState.HOVER]: {
        color: 'white',
        background: 'black',
      },
      [ButtonState.ACTIVE]: {
        color: 'white',
        background: '#3F3F3F',
      },
      [ButtonState.DISABLED]: {
        color: 'white',
        background: '#808080',
      },
    },
    [ButtonVariant.SIGNAL_FILLED]: {
      [ButtonState.REGULAR]: {
        color: 'white',
        background: '#EF5085',
      },
      [ButtonState.HOVER]: {
        color: 'white',
        background: '#EF5085',
      },
      [ButtonState.ACTIVE]: {
        color: 'white',
        background: '#CC3567',
      },
      [ButtonState.DISABLED]: {
        color: 'white',
        background: '#808080',
      },
    },
    [ButtonVariant.ACTION_FILLED]: {
      [ButtonState.REGULAR]: {
        color: 'black',
        background: '#B6FFE2',
      },
      [ButtonState.HOVER]: {
        color: 'black',
        background: '#B6FFE2',
      },
      [ButtonState.ACTIVE]: {
        color: 'black',
        background: '#19A68F',
      },
      [ButtonState.DISABLED]: {
        color: 'white',
        background: '#808080',
      },
    },
    [ButtonVariant.HOLLOW]: {
      [ButtonState.REGULAR]: {
        color: 'white',
        background: 'none',
      },
      [ButtonState.HOVER]: {
        color: 'white',
        background: 'none',
      },
      [ButtonState.ACTIVE]: {
        color: 'black',
        background: '#AA8AF7',
      },
      [ButtonState.DISABLED]: {
        color: '#B4B4B4',
        background: 'none',
      },
    },
    [ButtonVariant.GLASS]: {
      [ButtonState.REGULAR]: {
        color: 'white',
        background: 'rgba(54, 127, 132, 0.3);',
      },
      [ButtonState.HOVER]: {
        color: 'white',
        background: 'rgba(173, 216, 208, 0.27);',
      },
      [ButtonState.ACTIVE]: {
        color: 'black',
        background: '#B6FFE2',
      },
      [ButtonState.DISABLED]: {
        color: '#B4B4B4',
        background: 'rgba(92, 92, 92, 0.3);',
      },
    },
    [ButtonVariant.GHOST]: {
      [ButtonState.REGULAR]: {
        color: 'white',
        background: 'none',
      },
      [ButtonState.HOVER]: {
        color: 'white',
        background: 'none',
      },
      [ButtonState.ACTIVE]: {
        color: 'white',
        background: 'none',
      },
      [ButtonState.DISABLED]: {
        color: '#B4B4B4',
        background: 'none',
      },
    },
  },
};
