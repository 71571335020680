import { prop } from 'datx';
import { Resource } from 'datx-jsonapi-react';

export class GoogleScholarTags extends Resource {
  public static type = 'google_scholar_tags/thin_lecture';

  @prop
  public citationTitle: string;

  @prop
  public citationAuthors: Array<string>;

  @prop
  public citationPublicationDate: string;

  @prop
  public citationPdfUrl: string;

  @prop
  public citationConferenceTitle: string;

  @prop
  public citationDoi?: string;

  @prop
  public citationAbstract: string;
}
