import { prop } from 'datx';
import { Resource } from 'datx-jsonapi-react';

export class LecturePackages extends Resource {
  public static type = 'library/lecture_packages';

  @prop
  public name: string;

  @prop
  public label: string;
}
