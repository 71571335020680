import { IToastType } from '@/enums/ToastType';

export const getToastColor = (theme, type: IToastType) => {
  switch (type) {
    case IToastType.SUCCESS:
      return 'green.500';

    case IToastType.INFO:
      return 'cyan.600';

    case IToastType.ERROR:
      return 'red.600';

    case IToastType.WARNING:
      return 'yellow.500';

    default:
      return 'green.500';
  }
};

const baseStyle = {
  container: {
    boxShadow: 'tooltip',
  },
};

function variantBright(props) {
  const { theme, status } = props;

  const color = getToastColor(theme, status);

  return {
    container: {
      width: { base: '100%', md: '550px' },
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      bg: 'white',
      borderRadius: 'full',
      py: 4,
      px: 6,
      mb: 6,
    },
    title: {
      flex: 1,
      textAlign: 'center',
    },
    icon: {
      background: color,
    },
  };
}

const variants = {
  bright: variantBright,
};

export default {
  baseStyle,
  variants,
};
