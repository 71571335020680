import { IJsonapiModel, Response } from 'datx-jsonapi';
import { Reducer, useCallback, useEffect, useReducer, useRef } from 'react';

import { useStores } from '@/hooks/useStores';

import { IMutationOptions } from '../interfaces/IMutationOptions';
import { MutationFn } from '../interfaces/MutaionFn';
import { MutationAction } from '../interfaces/MutationAction';
import { MutationResult } from '../interfaces/MutationResult';
import { MutationState } from '../interfaces/MutationState';

function useGetLatest<Value>(value: Value): () => Value {
  const ref = useRef<Value>(value);

  useEffect(() => {
    ref.current = value;
  });

  return useCallback(() => ref.current, []);
}

const initialState: MutationState<never> = { status: 'idle' };

const reducer = <TModel extends IJsonapiModel>(_, action): MutationState<TModel> => {
  if (action.type === 'RESET') {
    return { status: 'idle' };
  }
  if (action.type === 'MUTATE') {
    return { status: 'running' };
  }
  if (action.type === 'SUCCESS') {
    return { status: 'success', data: action.data };
  }
  if (action.type === 'FAILURE') {
    return { status: 'failure', error: action.error };
  }

  throw Error('Invalid action');
};

/**
 * Replace with useSWRMutation when it's released https://github.com/vercel/swr/pull/1450
 */
export function useMutation<TInput, TModel extends IJsonapiModel = IJsonapiModel>(
  mutationFn: MutationFn<TInput, TModel>,
  { onMutate, onSuccess, onFailure, onSettled, useErrorBoundary = false }: IMutationOptions<TInput, TModel> = {}
): MutationResult<TInput, TModel> {
  const { store } = useStores();

  const [{ status, data, error }, dispatch] = useReducer<Reducer<MutationState<TModel>, MutationAction<TModel>>>(
    reducer,
    initialState
  );

  const getMutationFn = useGetLatest(mutationFn);
  const getOnMutate = useGetLatest(onMutate);
  const getOnSuccess = useGetLatest(onSuccess);
  const getOnFailure = useGetLatest(onFailure);
  const getOnSettled = useGetLatest(onSettled);
  const latestMutation = useRef(0);

  const mutate = useCallback(async function mutate(input: TInput) {
    const mutation = Date.now();

    latestMutation.current = mutation;

    dispatch({ type: 'MUTATE' });
    const rollback = await getOnMutate()?.({ input });

    try {
      const response = await getMutationFn()(store, input);

      await getOnSuccess()?.({ data: response, input });

      if (latestMutation.current === mutation) {
        dispatch({ type: 'SUCCESS', data: response });
      }

      await getOnSettled()?.({ status: 'success', data: response, input });

      return response;
    } catch (err) {
      const response = err as Response<TModel>;

      await getOnFailure()?.({ error: response, rollback, input });

      await getOnSettled()?.({ status: 'failure', error: response, input, rollback });

      if (latestMutation.current === mutation) {
        dispatch({ type: 'FAILURE', error: response });
      }

      throw response;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reset = useCallback(() => {
    dispatch({ type: 'RESET' });
  }, []);

  if (useErrorBoundary && error) {
    throw error;
  }

  return [mutate, { status, data, error, reset }];
}
