import { useRef } from 'react';

interface IResultBox<T> {
  v: T;
}

export function useConstant<T>(fn: () => T): T {
  const ref = useRef<IResultBox<T>>();

  if (!ref.current) {
    ref.current = { v: fn() };
  }

  return ref.current.v;
}
