import { chakra, Container } from '@chakra-ui/react';

export const ErrorWrapper = chakra('div', {
  baseStyle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    bg: 'gradientBackground',
    width: 'full',
    minH: '100vh',
  },
  label: 'ErrorWrapper',
});

export const ErrorContainer = chakra(Container, {
  baseStyle: {
    display: 'grid',
    gridTemplateColumns: 'repeat(12, 1fr)',
    columnGap: '16px',
    rowGap: '56px',
    py: 12,
  },
  label: 'ErrorContainer',
});

export const ErrorCode = chakra('div', {
  baseStyle: {
    gridColumn: ['1 / span 12', null, null, '2 / 6'],
    alignSelf: 'center',
  },
  label: 'ErrorCode',
});

export const ErrorMessage = chakra('div', {
  baseStyle: {
    gridColumn: ['1 / span 12', null, null, '7 / 12'],
  },
  label: 'ErrorMessage',
});
