import { FontFamily } from '../../enums/index';

export const fontFamilies: Record<FontFamily, string> = {
  [FontFamily.IBMPlexSans]: `${FontFamily.IBMPlexSans}, Roboto, Arial, serif`,
  [FontFamily.IBMPlexSerif]: `${FontFamily.IBMPlexSerif}, Times New Roman, serif`,
  [FontFamily.SpaceGrotesk]: `${FontFamily.SpaceGrotesk}, Helvetica, Arial, sans-serif`,
  [FontFamily.SpaceGroteskUnderline]: `
    ${FontFamily.SpaceGroteskUnderline}, ${FontFamily.SpaceGroteskUnderline}, Helvetica, Arial, sans-serif
  `,
  [FontFamily.NotoSansTC]: `${FontFamily.NotoSansTC}, Helvetica Neue, Helvetica, Arial, sans-serif`,
  [FontFamily.Mada]: `${FontFamily.Mada}, Helvetica Neue, Helvetica, Arial, sans-serif`,
};
