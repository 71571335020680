import { prop } from 'datx';
import { Resource } from 'datx-jsonapi-react';

import { ThinProfile } from '@/resources/ThinProfile';
import { ThinSubject } from '@/resources/ThinSubject';

export class OriginalsSeries extends Resource {
  public static type = 'originals/series';

  @prop
  public title: string;

  @prop
  public subtitle: string;

  @prop
  public slug: string;

  @prop
  public description: string;

  @prop
  public originalHeaderImageUrl: string;

  @prop
  public largeHeaderImageUrl: string;

  @prop
  public mediumHeaderImageUrl: string;

  @prop
  public smallHeaderImageUrl: string;

  @prop
  public thumbnailHeaderImageUrl: string;

  @prop
  public preloaderHeaderImageUrl: string;

  @prop.toOne('thin_subjects')
  public subject: ThinSubject;

  @prop.toOne('thin_profiles')
  public author: ThinProfile;
}
