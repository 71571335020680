import { prop } from 'datx';
import { computed } from 'mobx';

import { BaseModel } from './BaseModel';
import { NewsArticleTopic } from './NewsArticleTopic';

export class NewsArticle extends BaseModel {
  public static type = 'news_articles';

  @prop
  public title: string;

  @prop
  public content: string;

  @prop
  public summary: string;

  @prop
  public status: 'draft' | 'published';

  @prop
  public publishedOn: string;

  @prop
  public readTime: string;

  @prop
  public author: string;

  @prop
  public slug: string;

  @prop
  public largeFeaturedImageUrl: string;

  @prop
  public mediumFeaturedImageUrl: string;

  @prop
  public preloaderFeaturedImageUrl: string;

  @prop
  public largeCoverImageUrl: string;

  @prop
  public mediumCoverImageUrl: string;

  @prop
  public preloaderPreloaderImageUrl: string;

  @prop.toMany(NewsArticleTopic)
  public newsArticleTopics: Array<NewsArticleTopic>;

  @computed
  public get linkSlug() {
    return `${this.id}-${this.slug}`;
  }
}
