import { Text, Heading } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';

import { ErrorWrapper, ErrorContainer, ErrorCode, ErrorMessage } from './elements';

interface IErrorLayoutProps {
  errorCode: string;
  message: string;
  children: ReactNode;
}

export const ErrorLayout: FC<IErrorLayoutProps> = ({ errorCode, message, children }) => (
  <ErrorWrapper>
    <ErrorContainer>
      <ErrorCode>
        <Heading as="h1" color="white" fontSize="159px" lineHeight="191px" textAlign={['left', null, null, 'right']}>
          {errorCode}
        </Heading>
      </ErrorCode>
      <ErrorMessage>
        <Text color="white" fontSize={['md', null, null, 'xl']} mb={children ? 14 : null}>
          {message}
        </Text>
        {children}
      </ErrorMessage>
    </ErrorContainer>
  </ErrorWrapper>
);
