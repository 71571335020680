import { prop } from 'datx';
import { Resource } from 'datx-jsonapi-react';
import { computed } from 'mobx';

import { CaseStudyCategory } from './CaseStudyCategory';

export class CaseStudy extends Resource {
  public static type = 'case_studies';

  @prop
  public title: string;

  @prop
  public shortDescription: string;

  @prop
  public content: string;

  @prop
  public status: 'draft' | 'published';

  @prop
  public slug: string;

  @prop
  public originalFeaturedImageUrl: string;

  @prop
  public largeFeaturedImageUrl: string;

  @prop
  public mediumFeaturedImageUrl: string;

  @prop
  public smallFeaturedImageUrl: string;

  @prop
  public thumbnailFeaturedImageUrl: string;

  @prop
  public preloaderFeaturedImageUrl: string;

  @prop.toMany('case_study_categories')
  public categories: Array<CaseStudyCategory>;

  @computed
  public get linkSlug() {
    return `${this.id}-${this.slug}`;
  }
}
