import { menuAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, cssVar, defineStyle, StyleFunctionProps } from '@chakra-ui/styled-system';

const { defineMultiStyleConfig, definePartsStyle } = createMultiStyleConfigHelpers(parts.keys);

const $bg = cssVar('menu-bg');
const $shadow = cssVar('menu-shadow');
const $color = cssVar('menu-color');

const baseStyleList = defineStyle({
  [$bg.variable]: 'colors.white',
  [$shadow.variable]: 'shadows.lg',
  [$color.variable]: 'inherit',
  _dark: {
    [$bg.variable]: 'colors.gray.700',
    [$shadow.variable]: 'shadows.dark-lg',
  },
  bg: $bg.reference,
  boxShadow: $shadow.reference,
  color: $color.reference,
  minW: '3xs',
  py: '2',
  zIndex: 1,
  borderRadius: 'sm',
  borderWidth: '1px',
  maxW: { base: '250px', sm: '300px' },
});

const baseStyleItem = defineStyle(({ colorScheme }: StyleFunctionProps) => ({
  py: '15px',
  px: '16px',
  transition: 'background 50ms ease-in 0s',
  fontFamily: 'Space Grotesk',
  fontWeight: 'semibold',
  fontSize: 'md',
  lineHeight: 'short',
  _focus: {
    [$bg.variable]: 'colors.gray.50',
    _dark: {
      [$bg.variable]: 'colors.whiteAlpha.100',
    },
  },
  _hover: {
    [$bg.variable]: 'colors.gray.50',
    [$color.variable]: `colors.${colorScheme}.400`,
    _dark: {
      [$bg.variable]: 'colors.whiteAlpha.200',
      [$color.variable]: 'colors.whiteAlpha.100',
    },
    _disabled: {
      [$bg.variable]: 'colors.transparent',
      [$color.variable]: 'colors.gray.200',
    },
  },
  _active: {
    [$bg.variable]: 'colors.gray.50',
    _dark: {
      [$bg.variable]: 'colors.whiteAlpha.200',
    },
  },
  _expanded: {
    [$bg.variable]: 'colors.gray.100',
    _dark: {
      [$bg.variable]: 'colors.whiteAlpha.100',
    },
  },
  _disabled: {
    [$color.variable]: 'colors.gray.200',
    cursor: 'not-allowed',
  },
  bg: $bg.reference,
  color: $color.reference,
}));

const baseStyle = definePartsStyle((props: StyleFunctionProps) => ({
  list: baseStyleList,
  item: baseStyleItem(props),
}));

const variantNavigation = (props: StyleFunctionProps) => {
  const { theme, colorScheme } = props;

  if (colorScheme === 'blackAlpha') {
    return {
      list: {
        [$bg.variable]: 'colors.black',
        _dark: {
          [$bg.variable]: 'colors.black',
        },
      },
      item: {
        [$bg.variable]: 'colors.black',
        [$color.variable]: 'colors.white',
        _focus: {
          [$bg.variable]: 'colors.gray.800',
        },
        _dark: {
          [$bg.variable]: 'colors.black',
        },
        _hover: {
          [$bg.variable]: 'colors.gray.800',
          [$color.variable]: 'colors.white',
          _dark: {
            [$bg.variable]: 'colors.gray.800',
            [$color.variable]: 'colors.white',
          },
          _disabled: {
            [$bg.variable]: 'transparent',
            [$color.variable]: 'colors.gray.200',
          },
        },
        _active: {
          [$bg.variable]: 'colors.gray.800',
        },
        _disabled: {
          [$color.variable]: 'colors.gray.200',
          cursor: 'not-allowed',
        },
      },
      button: {
        borderRadius: '50%',
        // Overriding some global.tsx hover/focus styles
        _hover: { boxShadow: `0 0 0 0.625rem ${theme.colors.gray['900']} !important` },
        _focus: { boxShadow: `0 0 0 0.625rem ${theme.colors.gray['800']} !important` },
      },
    };
  }

  // violet color scheme
  return {
    button: {
      borderRadius: '50%',
      // Overriding some global.tsx hover/focus styles
      _hover: { boxShadow: `0 0 0 0.625rem ${theme.colors.gray['100']} !important` },
      _focus: { boxShadow: `0 0 0 0.625rem ${theme.colors.gray['200']} !important` },
    },
  };
};

const variants = {
  navigation: variantNavigation,
};

const defaultProps = {
  colorScheme: 'violet',
};

const menuTheme = defineMultiStyleConfig({
  baseStyle,
  variants,
  defaultProps,
});

export default menuTheme;
