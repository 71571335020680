const defaultProps = {
  variant: 'default',
  colorScheme: 'violet',
};

const banner = (props) => {
  const { colorScheme } = props;

  return {
    bg: `gradientBanners.${colorScheme}`,
  };
};

export default {
  baseStyle: {
    width: '100%',
    display: 'flex',
  },
  variants: {
    default: {
      bg: 'white',
    },
    dark: {
      bg: 'black',
    },
    dimmed: {
      bg: 'gray.50',
    },
    banner,
  },
  defaultProps,
};
