import { prop } from 'datx';

import { Resource } from '@/lib/datx-jsonapi-react';

export class EventDay extends Resource {
  public static type = 'event_days';

  @prop
  public name: string;

  @prop
  public current: boolean;
}
