const parts = ['container', 'clickAwayArea', 'list'];

export default {
  parts,
  baseStyle: {
    container: {
      position: 'relative',
      display: 'inline-block',
    },
    clickAwayArea: {
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 1,
    },
    list: {
      position: 'absolute',
      zIndex: 3,
      height: 0,
      opacity: 0,
      [`&[data-active='true']`]: {
        height: 'auto',
        opacity: 1,
      },
    },
  },
  variants: {},
  sizes: {},
};
