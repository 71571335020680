import { prop } from 'datx';
import { Resource } from 'datx-jsonapi-react';

export class LectureStatistic extends Resource {
  public static type = 'lecture_statistics';

  @prop
  public videoStartedCount: number;

  @prop
  public citationsCount: number;

  @prop
  public sharesCount: number;

  @prop
  public commentsCount: number;
}
