const activeLabelStyles = {
  transform: 'scale(0.85) translateY(-24px)',
};

const variants = {
  floating: {
    container: {
      _focusWithin: {
        label: {
          ...activeLabelStyles,
        },
      },
      'input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label': {
        ...activeLabelStyles,
      },
      label: {
        top: 0,
        left: 0,
        zIndex: 2,
        position: 'absolute',
        backgroundColor: 'white',
        pointerEvents: 'none',
        my: 2,
        transformOrigin: 'left top',
        color: 'gray.600',
        lineHeight: 6,
        fontFamily: 'body',
        fontWeight: 'normal',
      },
    },
  },
};

export default {
  variants,
};
