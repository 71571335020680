import { prop } from 'datx';
import { Resource } from 'datx-jsonapi-react';

export class ProfileStatistics extends Resource {
  public static type = 'profile_statistics';

  @prop
  public lecturesCount: number;

  @prop
  public videoStartedCount: number;

  @prop
  public citationsCount: number;
}
