import { prop } from 'datx';
import { getModelMeta } from 'datx-jsonapi';
import { Resource } from 'datx-jsonapi-react';
import { computed } from 'mobx';

import { NotificationType } from '@/enums/NotificationType';
import { ResourceType } from '@/enums/ResourceType';
import { EventChatChannel } from '@/resources/EventChatChannel';
import { ThinLecture } from '@/resources/ThinLecture';

export class Notification extends Resource {
  public static type = 'notifications';

  @prop
  public type: NotificationType;

  @prop
  public resourceType: ResourceType;

  @prop
  public resourceId: string;

  @prop
  public read: boolean;

  @prop
  public createdAt: string;

  @prop.toOne('thin_lectures')
  public lecture: ThinLecture;

  @prop.toOne('event_chat_channels')
  public eventChatChannel: EventChatChannel;

  @computed
  get resourceMeta() {
    return getModelMeta(this);
  }
}
