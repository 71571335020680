import { prop } from 'datx';

import { BaseModel } from './BaseModel';

export class ScheduleEntry extends BaseModel {
  public static type = 'schedule_entries';

  @prop
  public name: string;

  @prop
  public startsAt: string;

  @prop
  public endsAt: string;
}
