import type { SystemStyleObject } from '@chakra-ui/theme-tools';

const highlight: SystemStyleObject = {
  color: 'violet.500',
  textDecoration: 'underline',
};

export default {
  variants: {
    highlight,
  },
};
