const baseStyle = (props: Record<string, any>) => {
  const { alignment = 'right', colorScheme } = props;

  const alignmentStyles =
    alignment === 'right'
      ? {
          bg: `${colorScheme}.50`,
          borderBottomRightRadius: '0',
          ml: 'auto',
        }
      : {
          bg: `gray.50`,
          borderBottomLeftRadius: '0',
        };

  return {
    px: 4,
    py: 2,
    display: 'inline-block',
    maxW: '80%',
    borderRadius: '12px',
    fontSize: 'sm',
    lineHeight: '1.25rem',
    whiteSpace: 'pre-line',
    textAlign: 'left',
    ...alignmentStyles,
  };
};

const defaultProps = {
  colorScheme: 'violet',
};

export default {
  baseStyle,
  defaultProps,
};
