import { prop } from 'datx';
import { Resource } from 'datx-jsonapi-react';

import { EventRegistrationType } from '@/enums/EventRegistrationType';
import { EventState } from '@/enums/EventState';

export class ThinEvent extends Resource {
  public static type = 'thin_events';

  @prop
  public city: string;

  @prop
  public countryId: string;

  @prop
  public conferenceId: string;

  @prop
  public name: string;

  @prop
  public state: EventState;

  @prop
  public startsAt: string;

  @prop
  public endsAt: string;

  @prop
  public featured: boolean;

  @prop
  public description: string;

  @prop
  public shortDescription: string;

  @prop
  public paid: boolean;

  @prop
  public peopleList: boolean;

  @prop
  public lecturesCount: string;

  @prop
  public speakersCount: string;

  @prop
  public subjectsCount: string;

  @prop
  public originalLogoUrl: string;

  @prop
  public smallLogoUrl: string;

  @prop
  public mediumLogoUrl: string;

  @prop
  public largeLogoUrl: string;

  @prop
  public thumbnailLogoUrl: string;

  @prop
  public preloaderLogoUrl: string;

  @prop
  public originalHeaderImageUrl: string;

  @prop
  public largeHeaderImageUrl: string;

  @prop
  public mediumHeaderImageUrl: string;

  @prop
  public smallHeaderImageUrl: string;

  @prop
  public thumbnailHeaderImageUrl: string;

  @prop
  public preloaderHeaderImageUrl: string;

  @prop
  public visible: boolean;

  /**
   * @deprecated use property on the EventConfiguration model
   */
  @prop
  public schedulePage: boolean;

  /**
   * @deprecated use property on the EventConfiguration model
   */
  @prop
  public mainStagePage: boolean;

  /**
   * @deprecated use property on the EventConfiguration model
   */
  @prop
  public sessionsPage: boolean;

  /**
   * @deprecated use property on the EventConfiguration model
   */
  @prop
  public postersPage: boolean;

  /**
   * @deprecated use property on the EventConfiguration model
   */
  @prop
  public expoPage: boolean;

  /**
   * @deprecated use property on the EventConfiguration model
   */
  @prop
  public loungePage: boolean;

  /**
   * @deprecated use property on the EventConfiguration model
   */
  @prop
  public helpdeskPage: boolean;

  @prop
  public missingTicketDescription: string;

  @prop
  public missingTicketTitle: string;

  @prop
  public registrationUrl: string;

  @prop
  public registrationType: EventRegistrationType;

  @prop
  public externalLoginUrl: string;

  @prop
  public counters?: Array<{
    name: string;
    count: string;
  }>;

  @prop
  public timeZone?: string;

  @prop
  public aaptEvent?: boolean;

  @prop
  public displayWithTime?: boolean;
}
