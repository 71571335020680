/* eslint-disable import/order */
import { extendTheme, ThemeTypings } from '@chakra-ui/react';

import { theme } from './legacy-default';
import { styles } from './styles';
import { colors } from './foundations/colors';
import { typography } from './foundations/typography';
import { shadows } from './foundations/shadows';
import { sizes } from './foundations/sizes';
import { spacing } from './foundations/spacing';
import { layerStyles } from '@/styles/themes/foundations/layerStyles';
import { textStyles } from './foundations/textStyles';
import { zIndices } from './foundations/zIndices';

// [hygen_inject - component imports]
import Search from './components/search';
import Badge from './components/badge';
import Button from './components/button';
import Message from './components/message';
import CircleLoader from './components/circle-loader';
import Text from './components/text';
import Heading from './components/heading';
import Menu from './components/menu';
import Modal from './components/modal';
import Container from './components/container';
import Dropdown from './components/dropdown';
import DropdownLegacy from './components/dropdown-legacy';
import Skeleton from './components/skeleton';
import Input from './components/input';
import Section from './components/section';
import LiveIndicator from './components/live-indicator';
import Avatar from './components/avatar';
import Alert from './components/alert';
import Tabs from './components/tabs';
import Tooltip from './components/tooltip';
import Overlay from './components/overlay';
import Popover from './components/popover';
import Switch from './components/switch';
import Link from './components/link';
import Stat from './components/stat';
import Form from './components/form';
import Card from './components/card';
import Media from './components/media';

export const chakraDefault = extendTheme({
  legacy: theme,
  colors,
  ...typography,
  shadows,
  sizes,
  layerStyles,
  textStyles,
  space: spacing,
  zIndices,
  components: {
    // [hygen_inject - component registration]
    Search,
    Section,
    Alert,
    CircleLoader,
    // @ts-ignore
    Button,
    // @ts-ignore
    Badge,
    // @ts-ignore
    Message,
    // @ts-ignore
    Text,
    // @ts-ignore
    Heading,
    // @ts-ignore
    Menu,
    Modal,
    Container,
    LiveIndicator,
    // @ts-ignore
    Dropdown,
    // @ts-ignore
    DropdownLegacy,
    // @ts-ignore
    Skeleton,
    // @ts-ignore
    Input,
    // @ts-ignore
    Avatar,
    // @ts-ignore
    Tabs,
    // @ts-ignore
    Tooltip,
    Overlay,
    // @ts-ignore
    Popover,
    Switch,
    Link,
    Stat,
    Form,
    Card,
    Media,
  },
  styles,
});

export type ChakraTheme = ThemeTypings & { legacy: typeof theme };

export * from './legacy-default';

export default chakraDefault;
