function baseStyle() {
  return {
    bgColor: 'white',
    color: 'black',
    px: '4',
    py: '1',
    boxShadow: '0px 0px 2px rgba(0, 0, 0, .15)',
    borderRadius: '9999px',
  };
}

export default {
  baseStyle,
};
