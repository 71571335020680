import { useCallbackRef } from '@chakra-ui/react';
import { useToast as useChakraToast, UseToastOptions } from '@chakra-ui/toast';

import { Toast } from '@/components/ui/shared/overlays/Toast';

export const useToast = (options?: UseToastOptions) => {
  const chakraToast = useChakraToast(options);

  return useCallbackRef((config?: UseToastOptions) => {
    const { status, title, ...rest } = config;

    chakraToast({
      isClosable: true,
      position: 'top',
      duration: 3000,
      render: ({ onClose }) => <Toast variant="bright" onClick={onClose} status={status} message={title} />,
      ...rest,
    });
  });
};
