import { prop } from 'datx';
import { Resource } from 'datx-jsonapi-react';

export class Hub extends Resource {
  public static type = 'hubs';

  @prop
  public name: string;

  @prop
  public slug: string;

  @prop
  public headerTitle: string;

  @prop
  public originalHeaderImageUrl: string;

  @prop
  public largeHeaderImageUrl: string;

  @prop
  public mediumHeaderImageUrl: string;

  @prop
  public smallHeaderImageUrl: string;

  @prop
  public thumbnailHeaderImageUrl: string;

  @prop
  public preloadHeaderImageUrl: string;
}
