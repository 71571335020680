import { prop } from 'datx';

import { Resource } from '@/lib/datx-jsonapi-react';
import { ThinLecture } from '@/resources/ThinLecture';
import { ThinProfile } from '@/resources/ThinProfile';

export class MenuGroupMember extends Resource {
  public static type = 'menu_group_members';

  @prop.toOne('thin_profiles')
  public profile?: ThinProfile;

  @prop.toOne('thin_lectures')
  public lecture?: ThinLecture;
}
