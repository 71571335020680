import { prop } from 'datx';
import { Resource } from 'datx-jsonapi-react';

export class Testimonial extends Resource {
  public static type = 'testimonials';

  @prop
  public title: string;

  @prop
  public text: string;

  @prop
  public authorName: string;

  @prop
  public authorInstitution: string;

  @prop
  public featured: string;

  @prop
  public originalAuthorImageUrl: string;

  @prop
  public mediumAuthorImageUrl: string;
}
