/**
 * @deprecated use Chakra UI
 */
export enum FontFamily {
  IBMPlexSerif = 'IBM Plex Serif',
  IBMPlexSans = 'IBM Plex Sans',
  SpaceGrotesk = 'Space Grotesk',
  SpaceGroteskUnderline = 'Space Grotesk Underline',
  Mada = 'Mada',
  NotoSansTC = 'Noto Sans TC',
}

/**
 * @deprecated use Chakra UI
 */
export enum FontWeight {
  normal = 400,
  medium = 500,
  semiBold = 600,
  bold = 700,
}

/**
 * @deprecated use the one from enums folder
 */
export enum TranscriptLanguages {
  Arabic = 'Arabic',
  Chinese = 'Chinese',
  English = 'English',
  French = 'French',
  German = 'German',
  Spanish = 'Spanish',
}

export enum OverlayType {
  LECTURE_CITE = 'lectureCite',
  NONE = '',
  RESET_PASSWORD = 'resetPassword',
  SEARCH = 'search',
  SOCIAL_SHARE = 'socialShare',
  CONTACT_THANKS = 'thanks',
  READ_MORE = 'readMore',
  REQUEST_MEETING = 'requestMeeting',
  REQUEST_MEETING_THANKS = 'requestMeetingThanks',
  RECORDING_CONSENT = 'recordingConsent',
  LEAVE_SESSION_CONFIRM = 'leaveSessionConfirm',
  REMOVE_FROM_CALL_CONFIRM = 'removeFromCallConfirm',
}

export enum SearchCategories {
  ALL = 'all',
  LECTURES = 'lectures',
  SPEAKERS = 'speakers',
  POSTERS = 'posters',
}

export enum ResourceType {
  Lecture = 'Lecture',
  Livestream = 'Livestream',
  EventSponsor = 'EventSponsor',
}

export enum ResourcePermission {
  Speaker = 'speaker',
  Moderator = 'moderator',
  Chair = 'chair',
  Attendee = 'attendee',
  Sponsor = 'sponsor',
}

export enum SponsorPackage {
  PLATINUM = 'platinum',
  GOLD = 'gold',
  SILVER = 'silver',
  BRONZE = 'bronze',
}

export enum SearchGroup {
  ALL = 'all',
  EVENT_SESSION = 'event_session',
  LECTURE = 'lecture',
  SPEAKERS = 'speakers',
}
