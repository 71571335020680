import { prop } from 'datx';
import { Resource } from 'datx-jsonapi-react';

import { EventState } from '@/enums/EventState';

export class OmnisearchThinEvent extends Resource {
  public static type = 'omnisearch/thin_events';

  @prop
  public name: string;

  @prop
  public state: EventState;

  @prop
  public startsAt: string;

  @prop
  public endsAt: string;

  @prop
  public shortDescription: string;

  @prop
  public lecturesCount: string;

  @prop
  public speakersCount: string;

  @prop
  public smallLogoUrl: string;

  @prop
  public mediumHeaderImageUrl: string;
}
