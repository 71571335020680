import { prop } from 'datx';

import { Resource } from '@/lib/datx-jsonapi-react';
import { TranscriptLanguage } from '@/resources/TranscriptLanguage';

export class Transcript extends Resource {
  public static type = 'transcripts';

  @prop
  public subtitleUrl: string;

  @prop
  public pdfUrl: string;

  @prop
  public autoGenerated: boolean;

  @prop.toOne('transcript_languages')
  public language: TranscriptLanguage;
}
