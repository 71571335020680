import { prop } from 'datx';
import { Resource } from 'datx-jsonapi-react';

export class LibrarySubjects extends Resource {
  public static type = 'library/subjects';

  @prop
  public fullName: string;

  @prop
  public lecturesCount: number;
}
