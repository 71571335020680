import { prop } from 'datx';
import { Resource } from 'datx-jsonapi-react';

export interface IVariables {
  chatType: string;
  userId: string;
  resourceType?: string;
  resourceId?: string;
}

export class Token extends Resource {
  public static type = 'tokens';

  /**
   * Channel ID
   */
  @prop
  public resourceUid: string;

  /**
   * Token
   */
  @prop
  public value: string;

  /**
   * lid - Local ID - https://github.com/json-api/json-api/pull/1244
   * https://app.staging.underline.io/api/v1/docs/#tag/Tokens/paths/~1api~1v1~1tokens~1{id}/get
   */
  public static generateLid({ chatType, userId, resourceType, resourceId }: IVariables): string {
    if (chatType === 'chat') {
      return `chat-${userId}`;
    }
    return `${chatType}-${userId}-${resourceType}-${resourceId}`;
  }
}
