import { Alert, AlertTitle, Button, chakra, useAlertStyles } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const ToastCircle = (props) => {
  const { icon } = useAlertStyles();

  return <chakra.div w={4} h={4} borderRadius="full" __css={icon} {...props} />;
};

export const Toast = ({ message, status, onClick, ...rest }) => {
  const { t } = useTranslation();

  return (
    <Alert status={status} {...rest}>
      <ToastCircle />
      <AlertTitle>{message}</AlertTitle>
      <Button variant="link" color="black" onClick={onClick}>
        {t('common.dismiss')}
      </Button>
    </Alert>
  );
};
