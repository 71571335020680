import { prop } from 'datx';

import { BaseModel } from './BaseModel';

export class EventSponsorStatus extends BaseModel {
  public static type = 'event_sponsor_statuses';

  @prop
  public streamActive: boolean;
}
