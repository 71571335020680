import { prop } from 'datx';
import { Resource } from 'datx-jsonapi-react';

export class OmnisearchOccurrence extends Resource {
  public static type = 'omnisearch/occurrences';

  @prop
  public foundIn: FoundIn;

  @prop
  public details: Array<Array<IPosterDocumentOccurrence>>;
}

export interface IPosterDocumentOccurrence {
  type: string;
  content: string;
  space_before: string;
  idx: number;
  highlight: boolean;
  position: {
    duration: number;
    timestamp: number;
    page: number;
    top: number;
    left: number;
    height: number;
    width: number;
  };
}

export enum FoundIn {
  POSTER_DOCUMENT = 'poster_document',
  TRANSCRIPT_EN = 'transcript-en',
  SLIDESHOW = 'slideshow',
  PAPER = 'paper',
}
