const variantNav = {
  content: {
    borderRadius: '12px',
    border: 'none',
    position: 'relative',
    w: '22.5rem',
    _before: {
      display: 'block',
      content: '""',
      borderRadius: '12px',
      boxShadow: 'card3xl',
      width: '100%',
      height: '100%',
      position: 'absolute',
    },
  },
  body: {
    zIndex: 10,
    overflow: 'hidden',
    h: 'full',
    p: 0,
  },
};

const variants = {
  nav: variantNav,
};

export default {
  variants,
};
