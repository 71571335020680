import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ErrorLayout } from '@/components/ui/shared/layouts/ErrorLayout/ErrorLayout';
import { ButtonVariant } from '@/styles/themes/button';

import { IBugsnagFallbackComponentProps } from '../../../../../types/bugsnag';
import { FilledButton } from '../../../../core/Button/variants/FilledButton';

export const BugsnagError: FC<IBugsnagFallbackComponentProps> = ({ clearError }) => {
  const { t } = useTranslation();

  return (
    <ErrorLayout errorCode="500" message={t('errorPage.message')}>
      <FilledButton variant={ButtonVariant.ACTION_FILLED} onClick={clearError}>
        {t('errorPage.buttonLabel')}
      </FilledButton>
    </ErrorLayout>
  );
};
