import { prop } from 'datx';
import { Resource } from 'datx-jsonapi-react';

import { ReactionType } from '@/enums/ReactionType';
import { Comment } from '@/resources/Comment';

export class Reaction extends Resource {
  public static type = 'reactions';

  @prop
  public type: ReactionType;

  @prop.toOne('comments')
  user: Comment;
}
