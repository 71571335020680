import { prop } from 'datx';
import { Resource } from 'datx-jsonapi-react';

export class ProfileInstitutions extends Resource {
  public static type = 'profile_institutions';

  @prop
  public name: string;

  @prop
  public city: string;

  @prop
  public country: string;

  @prop
  public roleName: string;

  @prop
  public department: string;
}
