/* eslint-disable no-underscore-dangle */
import { IJsonapiModel, IRawResponse, IRequestOptions, Response } from 'datx-jsonapi';

import { Client } from '@/lib/datx-jsonapi-react';
import { ListResponse } from '@/lib/datx-jsonapi-react/interfaces/ListResponse';

import { _QueryResource } from './types';

export function pickRequestOptions(options?: IRequestOptions) {
  return { headers: options?.headers, skipCache: options?.skipCache };
}

export function getRawResponse<TModel extends IJsonapiModel>(response: Response<TModel>) {
  return (response as any)?.__response as IRawResponse;
}

export function cloneResponse<TModel extends IJsonapiModel>(
  response: Response<TModel>,
  overrideData?: TModel | Array<TModel>
) {
  const rawResponse = (response as any).__response as IRawResponse;
  const collection = (response as any).__collection as Client;
  const options = (response as any).__options as IRequestOptions;
  const views = response.views;

  return new Response(rawResponse, collection, options, overrideData, views) as Response<TModel>;
}

export const loadAll = async <TModel extends IJsonapiModel>(response: Response<TModel>) => {
  if (!response.next) {
    return response;
  } else {
    const newRes = await response.next;
    const newData = [...(response.data as Array<TModel>), ...(newRes.data as Array<TModel>)];

    return loadAll(cloneResponse(newRes, newData));
  }
};

export const undefinedToNull = <TProps>(props: TProps): TProps =>
  props ? (JSON.parse(JSON.stringify(props)) as TProps) : null;

export function isLastPage(data: ListResponse) {
  return data?.links.next === undefined;
}

export function isLastPageInfinite(data: Array<ListResponse>) {
  if (!data) {
    return false;
  }

  return isLastPage(data[data.length - 1]);
}
