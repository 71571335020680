import { prop } from 'datx';
import { Resource } from 'datx-jsonapi-react';

import { IOmnisearchFilterItem } from '@/interfaces/search';

export class OmnisearchLecturesFilter extends Resource {
  public static type = 'omnisearch/thin_lecture_filters';

  @prop
  public selectedSubject: IOmnisearchFilterItem;

  @prop
  public selectedPublisher: IOmnisearchFilterItem;

  @prop
  public tags: Array<IOmnisearchFilterItem>;

  @prop
  public yearsHeldAt: Array<IOmnisearchFilterItem>;

  @prop
  public package: Array<IOmnisearchFilterItem>;
}
