import { prop } from 'datx';

import { BaseModel } from './BaseModel';

export class Lounge extends BaseModel {
  public static type = 'lounge_entries';

  @prop
  public title: string;

  @prop
  public description: string;

  @prop
  public externalUrl: string;

  @prop
  public buttonText: string;

  @prop
  public position: number;

  @prop
  public primaryColor: string;

  @prop
  public secondaryColor: string;

  @prop
  public textColor: string;
}
