import { getColor, mode } from '@chakra-ui/theme-tools';

type Dict = Record<string, any>;

const baseStyle = {
  bg: 'transparent',
  color: 'black',
  _dark: {
    color: 'black',
  },
  lineHeight: '1.2',
  borderRadius: 'full',
  fontWeight: 'bold',
  fontFamily: 'Space Grotesk',
  _focus: {
    boxShadow: 'outline',
  },
  _disabled: {
    opacity: 1,
    cursor: 'not-allowed',
    boxShadow: 'none',
  },
  _hover: {
    fontFamily: 'Space Grotesk Underline',
    _disabled: {
      fontFamily: 'Space Grotesk',
      bg: 'initial',
    },
  },
  _active: {
    fontFamily: 'Space Grotesk Underline',
  },
};

function variantOutline(props: Dict) {
  const { colorScheme } = props;

  let colorSchemaStyles = {
    _hover: {
      bg: `${colorScheme}.500`,
      borderColor: `${colorScheme}.500`,
      color: `white`,
      _disabled: {
        bg: 'transparent',
      },
    },
    _active: {
      bg: `${colorScheme}.600`,
      borderColor: `${colorScheme}.600`,
    },
  };

  if (colorScheme === 'white') {
    colorSchemaStyles = {
      _hover: {
        bg: 'white',
        borderColor: 'black',
        color: `black`,
        _disabled: {
          bg: 'white',
        },
      },
      _active: {
        bg: 'white',
        borderColor: 'black',
      },
    };
  }

  return {
    border: '1px solid',
    borderColor: 'gray.200',
    color: 'black',
    bg: 'transparent',
    ...colorSchemaStyles,
    _disabled: {
      color: 'gray.200',
      borderColor: 'gray.200',
    },
  };
}

function variantSolid(props: Dict) {
  const { colorScheme } = props;

  if (colorScheme === 'black') {
    return {
      bg: mode(`black`, `white`)(props),
      _hover: {
        bg: mode(`black`, `white`)(props),
        _disabled: {
          bg: 'gray.200',
        },
      },
      _active: { bg: mode(`gray.800`, `gray.200`)(props) },
      _disabled: {
        bg: 'gray.200',
      },
    };
  }

  if (colorScheme === 'white') {
    return {
      bg: 'white',
      color: 'black',
      _hover: {
        _disabled: {
          bg: 'gray.200',
        },
      },
      _active: {
        bg: 'gray.800',
        _dark: {
          bg: 'gray.200',
        },
      },
      _disabled: {
        bg: 'gray.200',
      },
    };
  }

  return {
    bg: `${colorScheme}.500`,
    color: 'white',
    _dark: {
      color: 'white',
    },
    _hover: {
      bg: `${colorScheme}.400`,
      _disabled: {
        bg: 'gray.200',
      },
    },
    _active: {
      bg: mode(`${colorScheme}.600`, `${colorScheme}.400`)(props),
    },
    _disabled: {
      bg: 'gray.200',
    },
  };
}

function variantLink(props: Dict) {
  const { colorScheme } = props;

  let colorSchemaStyles = {
    color: `${colorScheme}.500`,
    _active: {
      color: `${colorScheme}.700`,
    },
  };

  if (colorScheme === 'white') {
    colorSchemaStyles = {
      color: `${colorScheme}`,
      _active: {
        color: `${colorScheme}`,
      },
    };
  }

  if (colorScheme === 'black') {
    colorSchemaStyles = {
      color: `${colorScheme}`,
      _active: {
        color: `${colorScheme}`,
      },
    };
  }

  return {
    padding: 0,
    height: 'auto',
    lineHeight: 'base',
    fontWeight: 'semibold',
    ...colorSchemaStyles,
    _disabled: {
      color: 'gray.200',
    },
  };
}

function variantMenu(props: Dict) {
  const { colorScheme, theme } = props;

  return {
    border: '1px solid',
    bg: 'transparent',
    borderColor: `gray.200`,
    textAlign: 'left',
    _hover: {
      bg: 'black',
      color: 'white',
      _dark: {
        color: 'white',
      },
      borderColor: mode(`black`, `white`)(props),
      fontFamily: 'Space Grotesk',
      _disabled: {
        color: 'gray.200',
        bg: 'transparent',
        borderColor: 'gray.200',
      },
    },
    _active: {
      bg: mode(`${colorScheme}.400`, `${colorScheme}.200`)(props),
      borderColor: mode(`${colorScheme}.400`, `${colorScheme}.200`)(props),
      color: mode('white', `black`)(props),
      fontFamily: 'Space Grotesk',
    },
    _disabled: {
      bg: 'transparent',
      color: 'gray.200',
      borderColor: 'gray.200',
    },
    _focus: {
      boxShadow: `0 0 0 1px ${getColor(theme, `${colorScheme}.500`)}`,
    },
    // to support truncate feature for button children https://github.com/chakra-ui/chakra-ui/issues/3862
    '& > span': {
      minW: 0,
    },
  };
}

const variantUnstyled = {
  bg: 'none',
  color: 'inherit',
  display: 'inline',
  lineHeight: 'inherit',
  m: 0,
  p: 0,
};

function variantNav(props: Dict) {
  const { colorScheme: c } = props;

  return {
    px: 4,
    borderRadius: '7px',
    color: `black`,
    width: '3.5rem',
    '> svg': {
      width: '2.5rem',
    },
    _hover: {
      bgColor: 'gray.50',
    },
    _active: {
      color: `${c}.700`,
      bgColor: `${c}.50`,
      _hover: {
        bgColor: `${c}.50`,
      },
    },
    _selected: {
      color: `${c}.700`,
      bgColor: `${c}.50`,
    },
  };
}

function glassButton(props: Dict) {
  return {
    bg: 'rgba(54, 127, 132, 0.30)',
    _hover: {
      bg: 'rgba(54, 127, 132, 0.40)',
      _disabled: {
        bg: 'gray.200',
      },
    },
    _disabled: {
      bg: 'gray.200',
    },
  };
}

const variants = {
  outline: variantOutline,
  solid: variantSolid,
  link: variantLink,
  menu: variantMenu,
  unstyled: variantUnstyled,
  nav: variantNav,
  glass: glassButton,
};

const sizes = {
  lg: {
    h: '56px',
    minW: 12,
    fontSize: 'md',
    lineHeight: 'base',
    px: 6,
  },
  // Not used in design
  md: {
    h: 10,
    minW: 10,
    fontSize: 'md',
    px: 4,
  },
  sm: {
    h: '40px',
    minW: 8,
    fontSize: 'md',
    lineHeight: 'base',
    px: 5,
  },
  // Not used in design
  xs: {
    h: 6,
    minW: 6,
    fontSize: 'xs',
    px: 2,
  },
};

const defaultProps = {
  variant: 'solid',
  size: 'lg',
  colorScheme: 'violet',
};

export default {
  baseStyle,
  variants,
  sizes,
  defaultProps,
};
