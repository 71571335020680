import { prop } from 'datx';
import { Resource } from 'datx-jsonapi-react';

import { ResourceType, ResourcePermission } from '@/enums/index';

import { User } from './User';

export class ResourceRole extends Resource {
  public static type = 'resource_roles';

  @prop
  public resourceType: ResourceType;

  @prop
  public role: ResourcePermission;

  @prop.toOne('users')
  public user: User;
}
