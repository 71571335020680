import styled from '@emotion/styled';
import { ElementType } from 'react';

import { FontFamily } from '@/enums/index';
import { ButtonTheme, ButtonVariant, ButtonState } from '@/styles/themes/button';
import { IThemeProps } from '@/styles/themes/types';

export interface ICoreButtonProps extends IThemeProps {
  variant?: ButtonVariant;
  buttonTheme?: ButtonTheme;
  disabled?: boolean;
  as?: ElementType;
}

export function getButtonThemeConfig({ theme, buttonTheme, variant }: ICoreButtonProps, state: ButtonState) {
  return theme.legacy.button[buttonTheme][variant][state];
}

export const CoreButton = styled.button<ICoreButtonProps>`
  padding: 16px 24px;

  font-family: ${({ theme }) => theme.legacy.typography.fontFamily[FontFamily.SpaceGrotesk]};
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;

  border: none;
  border-radius: 100px;
  outline: none;
  cursor: pointer;

  display: inline-flex;
  align-items: center;

  background: ${({ theme, buttonTheme, variant }) =>
    theme.legacy.button[buttonTheme][variant][ButtonState.REGULAR].background};
  color: ${(props) => getButtonThemeConfig(props, ButtonState.REGULAR).color};

  &:hover {
    background: ${(props) => getButtonThemeConfig(props, ButtonState.HOVER).background};
    color: ${(props) => getButtonThemeConfig(props, ButtonState.HOVER).color};
  }

  &:active {
    background: ${(props) => getButtonThemeConfig(props, ButtonState.ACTIVE).background};
    color: ${(props) => getButtonThemeConfig(props, ButtonState.ACTIVE).color};
  }

  &:hover,
  &:active {
    text-decoration: underline;
  }

  &:disabled {
    background: ${(props) => getButtonThemeConfig(props, ButtonState.DISABLED).background};
    color: ${(props) => getButtonThemeConfig(props, ButtonState.DISABLED).color};
  }
`;
