const baseStyle = {
  fontFamily: 'heading',
  fontWeight: 'bold',
};

const sizes = {
  '3xl': {
    fontSize: ['5xl', null, '6xl'],
    lineHeight: 'shorter',
  },
  '2xl': {
    fontSize: ['4xl', null, '5xl'],
    lineHeight: 'shorter',
  },
  xl: {
    fontSize: ['3xl', null, '4xl'],
    lineHeight: ['short', null, 'shorter'],
  },
  lg: {
    fontSize: ['2xl', null, '3xl'],
    lineHeight: ['short', null, 'shorter'],
  },
  md: {
    fontSize: ['xl', null, '2xl'],
    lineHeight: 'shorter',
  },
  sm: {
    fontSize: ['md', null, 'xl'],
    lineHeight: 'shorter',
  },
};

const defaultProps = {
  size: 'xl',
};

export default {
  baseStyle,
  sizes,
  defaultProps,
};
