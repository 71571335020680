import { prop } from 'datx';

import { BaseModel } from './BaseModel';
import { MenuTab } from './MenuTab';

export class MenuGroup extends BaseModel {
  public static type = 'menu_groups';

  @prop
  public name: string;

  @prop
  public type: 'speakers' | 'chairs' | 'lectures' | 'posters';

  @prop.toOne('menu_tabs')
  public menuTab: MenuTab;
}
