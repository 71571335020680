import { prop } from 'datx';

import { BaseModel } from './BaseModel';
import { EventSponsor } from './EventSponsor';

export type SponsorPackageType = 'big' | 'medium' | 'small';

export class EventSponsorPackage extends BaseModel {
  public static type = 'event_sponsor_packages';

  @prop
  public name: string;

  @prop
  public type: SponsorPackageType;

  @prop
  public position: number;

  @prop.toMany('event_sponsors')
  public eventSponsors: Array<EventSponsor>;
}
