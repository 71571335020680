function baseStyle() {
  return {};
}

const defaultProps = {
  // size: 'md',
  colorScheme: 'violet',
};

export default {
  baseStyle,
  defaultProps,
};
