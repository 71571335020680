/* eslint-disable */
import { useState } from 'react';
import Script from 'next/script';
import { Box, Icon } from '@chakra-ui/react';
import AccessiBeIcon from '@/assets/icons/ic-accessiBe.svg';
import { useRouter } from 'next/router';

declare global {
  interface Window {
    acsbJS: {
      init: any;
    };
  }
}

const ACCENT_COLOR = '#5b1cf4';

const ignoredRoutes = ['/embed'];

export const AccessiBeWidget = () => {
  const [isShowTrigger, setIsShowTrigger] = useState(false);
  const router = useRouter();
  const ignoreRoute = ignoredRoutes.some((dr) => router.pathname.includes(dr));

  const handleOnLoad = () => {
    if (!window?.acsbJS?.init) {
      return;
    }

    window.acsbJS.init({
      statementLink: '',
      footerHtml: '',
      hideMobile: true,
      hideTrigger: true,
      disableBgProcess: false,
      language: 'en',
      position: 'right',
      leadColor: ACCENT_COLOR,
      triggerColor: ACCENT_COLOR,
      triggerRadius: '50%',
      triggerPositionX: 'right',
      triggerPositionY: 'bottom',
      triggerIcon: 'people',
      triggerSize: 'medium',
      triggerOffsetX: 0,
      triggerOffsetY: 0,
      mobile: {
        triggerSize: 'small',
        triggerPositionX: 'right',
        triggerPositionY: 'center',
        triggerOffsetX: 0,
        triggerOffsetY: 0,
        triggerRadius: '50%',
      },
    });
    setIsShowTrigger(true);
  };

  if (ignoreRoute) return null;

  return (
    <Box
      id="accessi-be-widget"
      data-acsb-custom-trigger="true"
      pos="fixed"
      bottom="65px"
      left="8px"
      cursor="pointer"
      zIndex={2100}
      display={{ base: 'none', lg: 'block' }}
    >
      {isShowTrigger && <Icon as={AccessiBeIcon} w="56px" h="56px" />}
      <Script strategy="afterInteractive" onLoad={handleOnLoad} src={'https://acsbapp.com/apps/app/dist/js/app.js'} />
    </Box>
  );
};
