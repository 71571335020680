import { prop } from 'datx';

import { BaseModel } from './BaseModel';

export class EventAnnouncement extends BaseModel {
  public static type = 'event_announcements';

  @prop
  public title: string;

  @prop
  public description: string;

  @prop
  public createdAt: string;
}
