export default {
  variants: {
    inline: {
      container: {
        dl: {
          display: 'flex',
          alignItems: 'baseline',
        },
      },
    },
  },
};
