import { StyleFunctionProps } from '@chakra-ui/styled-system';

import button from './button';
import menu from './menu';

const parts = ['item', 'list', 'button', 'icon'];

function baseStyleList(props: StyleFunctionProps) {
  return {
    overscrollBehavior: 'contain',
    maxH: 224,
    overflow: 'auto',
    ...menu.baseStyle(props).list,
  };
}

function baseStyleItem(props: StyleFunctionProps) {
  return {
    ...menu.baseStyle(props).item,
  };
}

function baseStyleButton(props: Record<string, any>) {
  const buttonStyleMenuVariant = button.variants.menu(props);

  return {
    ...button.baseStyle,
    ...buttonStyleMenuVariant,
    ...button.sizes.sm,
    '&[aria-expanded="true"]': {
      // eslint-disable-next-line no-underscore-dangle
      ...buttonStyleMenuVariant._active,
    },
  };
}

const baseStyleIcon = {
  ml: 2,
  display: 'inline-block',
};

const baseStyle = (props: StyleFunctionProps) => {
  return {
    list: baseStyleList(props),
    item: baseStyleItem(props),
    button: baseStyleButton(props),
    icon: baseStyleIcon,
  };
};

const defaultProps = {
  colorScheme: 'violet',
};

/**
 * This is the custom theme for a custom Dropdown component. It's not a part of Chakra UI library.
 *
 * TODO: check if this is used anywhere, if not then remove it. Search for usage of `useMultiStyleConfig('Dropdown` in the codebase.
 */
export default {
  parts,
  baseStyle,
  defaultProps,
};
