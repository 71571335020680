import { Response } from 'datx-jsonapi';

import { Client } from '@/lib/datx-jsonapi-react';

export const hydrate = (store: Client, fallback: Record<string, any>) => {
  return Object.keys(fallback).reduce((previousValue, currentValue) => {
    const data = fallback[currentValue];

    if (store && data) {
      if (Array.isArray(data)) {
        previousValue[currentValue] = data.map((rawDataObject) => {
          const { rawData, rawSideloadData } = rawDataObject;

          // datx will automatically hydrate sideloaded data to store
          // and computed values will be calculated correctly
          if (rawSideloadData) {
            new Response({ data: rawSideloadData, status: 200 }, store);
          }

          return new Response({ data: rawData, status: 200 }, store);
        });
      } else {
        previousValue[currentValue] = new Response({ data, status: 200 }, store);
      }
    }

    return previousValue;
  }, {});
};
