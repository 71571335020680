import { prop } from 'datx';
import { getModelLinks, getModelMeta } from 'datx-jsonapi';
import { Resource } from 'datx-jsonapi-react';
import { computed } from 'mobx';

import { ThinLecture } from '@/resources/ThinLecture';
import { User } from '@/resources/User';

interface ICommentMeta {
  thumbsUpCount: number;
  repliesCount: number;
  createdAt: string;
  updatedAt: string;
  anonymizedAt: string;
}

interface ICommentLinks {
  anonymize: string;
}

export class Comment extends Resource {
  public static type = 'comments';

  @prop
  public content: string;

  @prop.toOne('thin_lectures')
  lecture: ThinLecture;

  @prop.toOne('users')
  user: User;

  @computed
  public get metadata() {
    return getModelMeta(this) as ICommentMeta;
  }

  @computed
  public get links() {
    return (getModelLinks(this) as unknown) as ICommentLinks;
  }
}
