import { prop } from 'datx';
import { computed } from 'mobx';

import { SponsorPackage } from '@/enums/index';

import { BaseModel } from './BaseModel';
import { EventSponsorPackage } from './EventSponsorPackage';
import { EventSponsorStatus } from './EventSponsorStatus';

interface IEnabledFeatures {
  qa: boolean;
  about: boolean;
  directLink: boolean;
}

export class EventSponsor extends BaseModel {
  public static type = 'event_sponsors';

  @prop
  public slug: string;

  @prop
  public description?: string;

  @prop
  public name: string;

  @prop
  public website: string;

  @prop
  public linkedin?: string;

  @prop
  public twitter?: string;

  @prop
  public facebook?: string;

  @prop
  public contact?: string;

  @prop
  public email?: string;

  @prop
  public ramDescription?: string;

  @prop
  public pageEnabled: boolean;

  @prop
  public originalLogoImageUrl: string;

  @prop
  public largeLogoImageUrl: string;

  @prop
  public mediumLogoImageUrl: string;

  @prop
  public smallLogoImageUrl: string;

  @prop
  public originalHeaderImageUrl: string;

  @prop
  public largeHeaderImageUrl: string;

  @prop
  public mediumHeaderImageUrl: string;

  @prop
  public smallHeaderImageUrl: string;

  @prop
  public package: SponsorPackage;

  @prop
  public enabledFeatures: IEnabledFeatures;

  @prop
  public helpdesk: boolean;

  @prop
  public opentokStreamSession: string;

  @prop
  public position: number;

  @prop.toOne('event_sponsor_packages')
  public eventSponsorPackage: EventSponsorPackage;

  @computed
  public get streamActive() {
    // eslint-disable-next-line prefer-destructuring
    const collection = this.meta?.collection;

    if (collection) {
      const status = collection.findOne(EventSponsorStatus, this.id);

      return Boolean(status?.streamActive);
    }

    return false;
  }

  // temporary present until streamActive is not removed from backend response
  // eslint-disable-next-line
  public set streamActive(value) {}

  @computed
  public get linkSlug() {
    return `${this.id}-${this.slug}`;
  }
}
