import { ThinProfile } from '@/resources/ThinProfile';

function getFullName({
  prefixName,
  firstName,
  lastName,
}: {
  prefixName: string;
  firstName: string;
  lastName: string;
}): string {
  return [prefixName, firstName, lastName].filter(Boolean).join(' ');
}

function getAvatar({ mediumSquareAvatarUrl }: ThinProfile): string {
  return mediumSquareAvatarUrl || '/images/placeholderSpeaker.png';
}

function getSquareAvatar({ mediumSquareAvatarUrl }: ThinProfile): string {
  return mediumSquareAvatarUrl || '/images/placeholderSquareSpeaker.png';
}

export { getFullName, getAvatar, getSquareAvatar };
