import { prop } from 'datx';
import { Resource } from 'datx-jsonapi-react';

import { ThinEvent } from '@/resources/ThinEvent';

import { User } from './User';

export class EventTicket extends Resource {
  public static type = 'event_tickets';

  @prop.toOne('thin_events')
  public event: ThinEvent;

  @prop.toOne('users')
  public user: User;
}
