import styled from '@emotion/styled';

import { ButtonVariant, ButtonTheme } from '@/styles/themes/button';

import { CoreButton, ICoreButtonProps } from '../CoreButton';

export const FilledButton = styled(CoreButton)<ICoreButtonProps>``;

FilledButton.defaultProps = {
  buttonTheme: ButtonTheme.LIGHT,
  variant: ButtonVariant.FILLED,
};
