import Button from './button';

const parts = ['root', 'tablist', 'tab', 'tabpanel', 'indicator'];

type Dict = Record<string, any>;

function baseStyleTab(props: Dict) {
  const { isFitted } = props;

  return {
    flex: isFitted ? 1 : undefined,
    fontFamily: 'heading',
    fontWeight: 'bold',
    transition: 'color 0.4s',
    _focus: {
      zIndex: 1,
      boxShadow: 'outline',
    },
  };
}

const baseStyle = (props: Dict) => ({
  root: {
    overflowX: 'auto',
  },
  tab: baseStyleTab(props),
});

function variantLine(props: Dict) {
  const { orientation } = props;
  const isVertical = orientation === 'vertical';
  const borderProp = orientation === 'vertical' ? 'borderLeft' : 'borderBottom';
  const marginProp = isVertical ? 'ml' : 'mb';

  return {
    tablist: {
      [borderProp]: '1px solid',
      borderColor: 'inherit',
    },
    tab: {
      [borderProp]: '4px solid',
      borderColor: 'transparent',
      // Override chakras default margin
      [marginProp]: 0,
      _selected: {
        color: 'violet.500',
        borderColor: 'currentColor',
      },
      _hover: {
        color: 'violet.600',
      },
      _active: {
        bg: 'transparent',
      },
      _disabled: {
        // TODO: how to compose multiple pseudo?
        // How to ignore hover pseudo when disabled is active
        color: 'inherit',
        opacity: 0.4,
        cursor: 'not-allowed',
      },
      _notFirst: {
        ml: '2rem',
      },
    },
    tabpanel: {
      px: 0,
      py: [7, 8],
    },
  };
}

function variantNav(props: Dict) {
  return {
    root: {
      h: 'full',
    },
    tab: {
      ...Button.variants.nav(props),
      py: 0,
      ml: { base: 4, md: 10 },
      _first: {
        ml: 0,
      },
      position: 'relative',
    },
    tablist: {
      borderBottom: '1px solid',
      borderColor: 'gray.200',
      px: { base: 5, md: 8 },
      py: 2,
      flex: '0 1 auto',
      minH: 0,
    },
    tabpanel: {
      h: '100%',
    },
  };
}

const variantPill = {
  tablist: {
    bg: 'gray.50',
    justifyContent: 'space-between',
    borderRadius: '48px',
  },
  tab: {
    flex: '1',
    lineHeight: '1.5rem',
    _selected: {
      color: 'white',
      bg: 'black',
      borderRadius: '48px',
    },
  },
};

const sizes = {
  xs: {
    tab: {
      fontSize: 'sm',
      lineHeight: 'shorter',
      px: 0,
      py: 3,
    },
  },
  sm: {
    tab: {
      fontSize: 'md',
      lineHeight: 'shorter',
      px: 0,
      py: 4,
    },
  },
  md: {
    tab: {
      fontSize: 'xl',
      lineHeight: '1.2',
      px: 0,
      py: 4,
    },
  },
  lg: {
    tab: {
      fontSize: '3xl',
      lineHeight: '1.2',
      px: 0,
      py: 4,
      minW: 14,
      minH: 14,
    },
  },
};

const variants = {
  line: variantLine,
  nav: variantNav,
  pill: variantPill,
};

const defaultProps = {
  size: 'lg',
  variant: 'line',
  colorScheme: 'violet',
};

export default {
  baseStyle,
  sizes,
  parts,
  variants,
  defaultProps,
};
