import { prop } from 'datx';
import { Resource } from 'datx-jsonapi-react';

export class ThinSubject extends Resource {
  public static type = 'thin_subjects';

  @prop
  public name: string;

  @prop
  public description: string;

  @prop
  public headline: string;

  @prop
  public slug: string;

  @prop
  public originalHeroImageUrl: string;

  @prop
  public largeHeroImageUrl: string;

  @prop
  public mediumHeroImageUrl: string;

  @prop
  public smallHeroImageUrl: string;

  @prop
  public thumbnailHeroImageUrl: string;

  @prop
  public preloaderHeroImageUrl: string;
}
