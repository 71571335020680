import { config, ICollectionFetchOpts, IRawResponse } from 'datx-jsonapi';
import * as fetch from 'isomorphic-fetch';

import { AppCollection as Appstore } from '@/stores/AppStore';
import { apify, deapify } from '@/utils/apify';
import { isServer } from '@/utils/isServer';

config.fetchReference = fetch;

config.cache = false;

config.baseUrl = process.env.NEXT_PUBLIC_API_URL;

config.defaultFetchOptions = {
  credentials: 'include',
  headers: {
    Accept: 'application/vnd.api+json',
    'Content-Type': 'application/vnd.api+json',
  },
};

config.transformResponse = (opts: IRawResponse) => {
  return { ...opts, data: deapify(opts.data) };
};

config.transformRequest = (opts: ICollectionFetchOpts) => {
  const store: Appstore = opts.collection as Appstore;

  // Prepare options
  if (!opts.options) {
    opts.options = {};
  }

  if (isServer) {
    if (opts.options.headers) {
      if (opts.options.headers['cookie']) {
        opts.options.headers['cookie'] = `${opts.options.headers['cookie']}; ${store.cookie}`;
      } else {
        opts.options.headers['cookie'] = store.cookie;
      }
    } else {
      opts.options = {
        headers: {
          cookie: store.cookie,
        },
      };
    }
  }

  // If we use raw link form the response
  if (!opts.url.startsWith(config.baseUrl)) {
    const rawUrl = new URL(opts.url);
    const baseUrl = new URL(config.baseUrl);

    rawUrl.hostname = baseUrl.hostname;
    rawUrl.port = baseUrl.port;
    rawUrl.protocol = baseUrl.protocol;

    opts.url = rawUrl.href;
  }

  return { ...opts, data: apify(opts.data) };
};
