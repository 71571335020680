const defaultProps = {
  size: 'xl',
};

export default {
  baseStyle: {
    backgroundColor: `almostRed.500`,
    display: 'inline-block',
    verticalAlign: 'middle',
    borderRadius: '50%',
  },
  sizes: {
    xl: {
      width: '16px',
      height: '16px',
    },
  },
  defaultProps,
};
