import { prop } from 'datx';
import { Resource } from 'datx-jsonapi-react';

export class AlgoliaFilterItem extends Resource {
  public static type = 'algoliasearch/filter_items';

  @prop
  public label: string;

  @prop
  public value: number;

  @prop
  public amount: number;
}
