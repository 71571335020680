import { prop } from 'datx';

import { BaseModel } from './BaseModel';
import { Transcript } from './Transcript';

export class TranscriptLanguage extends BaseModel {
  public static type = 'transcript_languages';

  @prop
  public name: string;

  @prop
  public position: number;

  @prop
  public locale: string;

  @prop.toOne('transcripts')
  public transcript: Transcript;
}
