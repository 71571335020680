import { Model } from 'datx';
import { jsonapi } from 'datx-jsonapi';
import { computed } from 'mobx';

/**
 * @deprecated use Resource from 'datx-jsonapi-react' instead
 */
export class BaseModel extends jsonapi(Model) {
  @computed
  public get id() {
    return this.meta?.id as string;
  }
}
