import { addHours, getMinutes, getHours, getSeconds } from 'date-fns';
import { AriaAttributes } from 'react';

export const ConditionalWrapper = ({ condition, wrapper, children }) => (condition ? wrapper(children) : children);

export function splitIdAndSlug(slug: string) {
  const index = slug.indexOf('-');

  return {
    id: slug.substr(0, index),
    slug: slug.substring(index + 1),
  };
}

/**
 * Gets the initials of the provided string.
 * If the string has more then 3 words, the first letter of the first two words will be taken.
 * @param name string
 */
export const getInitials = (name: string): string =>
  name
    .split(' ')
    .slice(0, 2)
    .map((word) => word.charAt(0).toUpperCase())
    .filter(Boolean)
    .join('');

export function isElementInViewport(element: HTMLElement): boolean {
  const rect = element.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

export const isBinaryAriaTrue = <T extends keyof AriaAttributes>(prop?: AriaAttributes[T]) => {
  if (prop === undefined) {
    return false;
  }

  if (typeof prop === 'string') {
    return prop === 'true';
  }

  return Boolean(prop);
};

export const convertSecondsToVideoDuration = (secondsAmount: number) => {
  const normalizeTime = (time: string): string => (time.length === 1 ? `0${time}` : time);

  const SECONDS_TO_MILLISECONDS_COEFF = 1000;
  const MINUTES_IN_HOUR = 60;

  const milliseconds = secondsAmount * SECONDS_TO_MILLISECONDS_COEFF;

  const date = new Date(milliseconds);
  const timezoneDiff = date.getTimezoneOffset() / MINUTES_IN_HOUR;
  const dateWithoutTimezoneDiff = addHours(date, timezoneDiff);

  const hours = normalizeTime(String(getHours(dateWithoutTimezoneDiff)));
  const minutes = normalizeTime(String(getMinutes(dateWithoutTimezoneDiff)));
  const seconds = normalizeTime(String(getSeconds(dateWithoutTimezoneDiff)));

  const hoursOutput = hours !== '00' ? `${hours}:` : '';

  return `${hoursOutput}${minutes}:${seconds}`;
};
