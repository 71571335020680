import { blacken } from '@chakra-ui/theme-tools';

const variantInverted = {
  closeButton: {
    color: 'white',
  },
  dialog: {
    bg: 'gray.900',
    borderRadius: '0',
    color: 'white',
    maxW: {
      base: 'full',
      md: '592px',
    },
    margin: 0,
    height: {
      base: '100%',
      sm: 'auto',
    },
    '@supports (backdrop-filter: blur(75px))': {
      background: {
        md: 'rgba(24, 25, 25, 0.8)',
      },
      backdropFilter: {
        md: 'blur(75px)',
      },
    },
  },
  body: {
    px: 10,
    py: {
      base: 20,
      md: 10,
    },
  },
  footer: {
    bg: blacken('gray.900', 20),
    justifyContent: 'start',
    px: 10,
  },
};

export default {
  baseStyle: (props) => ({
    dialogContainer: {
      display: props.scrollBehavior === 'inside' ? 'flex' : 'grid',
    },
  }),
  variants: { inverted: variantInverted },
};
