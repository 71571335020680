import { prop } from 'datx';
import { Resource } from 'datx-jsonapi-react';
import { computed } from 'mobx';

import { User } from '@/resources/User';
import { getLinkSlug } from '@/utils/models/common';
import { getAvatar, getFullName, getSquareAvatar } from '@/utils/models/speaker';

export class ThinProfile extends Resource {
  public static type = 'thin_profiles';

  @prop
  public prefixName: string;

  @prop
  public email: string;

  @prop
  public eventUpdatesSubscription: boolean;

  @prop
  public libraryUpdatesSubscription: boolean;

  @prop
  public emailPublic: boolean;

  @prop
  public emailNotificationOnLectureComment: boolean;

  @prop
  public emailNotificationOnEventAnnouncement: boolean;

  @prop
  public emailNotificationOnLectureReply: boolean;

  @prop
  public weeklyAnalyticsEmailNotification: boolean;

  @prop
  public engineeringTechnologySubscription: boolean;

  @prop
  public healthSciencesSubscription: boolean;

  @prop
  public lifeSciencesSubscription: boolean;

  @prop
  public socialSciencesSubscription: boolean;

  @prop
  public newsletterSubscription: boolean;

  @prop
  public physicsSubscription: boolean;

  @prop
  public computerScienceSubscription: boolean;

  @prop
  public pendingSubscription: boolean;

  @prop
  public eventUpdates: boolean;

  @prop
  public libraryUpdates: boolean;

  @prop
  public shortBio: string;

  @prop
  public firstName: string;

  @prop
  public lastName: string;

  @prop
  public institution: string;

  @prop
  public organizationalRole: string;

  @prop
  public originalSquareAvatarUrl: string;

  @prop
  public mediumSquareAvatarUrl: string;

  @prop
  public slug: string;

  @prop
  public website: string;

  @prop
  public linkedin: string;

  @prop
  public orchid: string;

  @prop
  public googleScholar: string;

  @prop.toOne(User)
  public user: User;

  @computed
  public get fullName() {
    return getFullName(this);
  }

  @computed
  public get title() {
    return [this.organizationalRole, this.institution].filter(Boolean).join(' @ ');
  }

  @computed
  public get linkSlug() {
    return getLinkSlug(this);
  }

  @computed
  public get avatar() {
    return getAvatar(this);
  }

  @computed
  public get squareAvatar() {
    return getSquareAvatar(this);
  }
}

export type ThinProfileAtributes = Partial<
  Pick<
    ThinProfile,
    | 'prefixName'
    | 'email'
    | 'emailPublic'
    | 'emailNotificationOnLectureComment'
    | 'emailNotificationOnLectureReply'
    | 'emailNotificationOnEventAnnouncement'
    | 'weeklyAnalyticsEmailNotification'
    | 'engineeringTechnologySubscription'
    | 'physicsSubscription'
    | 'eventUpdates'
    | 'libraryUpdates'
    | 'computerScienceSubscription'
    | 'healthSciencesSubscription'
    | 'lifeSciencesSubscription'
    | 'socialSciencesSubscription'
    | 'newsletterSubscription'
    | 'pendingSubscription'
    | 'shortBio'
    | 'firstName'
    | 'lastName'
    | 'institution'
    | 'organizationalRole'
    | 'originalSquareAvatarUrl'
    | 'mediumSquareAvatarUrl'
    | 'slug'
    | 'eventUpdatesSubscription'
    | 'libraryUpdatesSubscription'
  >
>;
