/* eslint-disable @typescript-eslint/ban-ts-comment */
import { GetServerSidePropsContext } from 'next';

import { AppCollection as AppStore } from '@/stores/AppStore';

import { isServer } from './isServer';

const NEXT_MOBX_STORE = 'store';

interface IGenerateStoreConfig {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  snapshot?: any;
  context?: GetServerSidePropsContext;
}

export function generateStore(config: IGenerateStoreConfig = {}): AppStore {
  const { snapshot, context } = config;

  if (isServer()) {
    return new AppStore(snapshot, context);
  }
  // @ts-ignore
  if (!global[NEXT_MOBX_STORE]) {
    // @ts-ignore
    global[NEXT_MOBX_STORE] = new AppStore(snapshot);
  }
  // @ts-ignore
  return global[NEXT_MOBX_STORE];
}
