function baseStyle(props: Record<string, any>) {
  const { colorScheme } = props;

  return {
    bg: `${colorScheme}.500`,
    p: 0,
    borderRadius: '50%',
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };
}

const sizes = {
  lg: {
    w: 6,
    h: 6,
    fontSize: '12px',
    lineHeight: '16px',
  },
  md: {
    w: 4,
    h: 4,
    fontSize: '8px',
    lineHeight: '16px',
  },
  sm: {
    w: 3,
    h: 3,
  },
  xs: {
    w: '0.375rem',
    h: '0.375rem',
  },
};

const defaultProps = {
  colorScheme: 'almostRed',
  variant: 'solid',
};

export default {
  baseStyle,
  defaultProps,
  sizes,
};
