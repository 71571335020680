import { prop } from 'datx';
import { Resource } from 'datx-jsonapi-react';

import { ThinLecture } from './ThinLecture';
import { ThinProfile } from './ThinProfile';

export type LectureProfileType = 'speaker' | 'author';

export class LectureProfile extends Resource {
  public static type = 'lecture_profiles';

  @prop
  public type: LectureProfileType;

  @prop.toOne('thin_profiles')
  public profile: ThinProfile;

  @prop.toOne('thin_lectures')
  public lecture: ThinLecture;
}
