import { getModelType } from 'datx';
import { prepareQuery } from 'datx-jsonapi';
import useSWRInfinite from 'swr/infinite';

import { useStores } from '@/hooks/useStores';
import { QueryResourcesInfinite, Resource, _QueryResources } from '@/lib/datx-jsonapi-react';
import { ListResponse } from '@/lib/datx-jsonapi-react/interfaces/ListResponse';

import { QueryInfiniteConfig } from '../types';

export function useResourcesInfinite<TModel extends Resource = Resource>(
  queryResources: QueryResourcesInfinite<TModel>,
  config?: QueryInfiniteConfig<TModel>
) {
  const { store } = useStores();

  const getKey = (pageIndex, previousPageData) => {
    const queryResourceResult = queryResources(pageIndex, previousPageData);

    if (Array.isArray(queryResourceResult)) {
      const [type, options] = queryResourceResult;

      const modelType = getModelType(type);

      const query = prepareQuery(modelType, undefined, undefined, options);

      return query.url;
    }

    return queryResourceResult;
  };

  const fetcher = async (...args: _QueryResources<TModel>) => {
    const [type, options] = args;
    const modelType = getModelType(type);

    const response = await store.request<TModel>(`${modelType}`, 'GET', null, options);

    if (config?.sideload) {
      await config.sideload(response);
    }

    if (config?.secondarySideload) {
      await config.secondarySideload(response);
    }

    return response as ListResponse<TModel>;
  };

  return useSWRInfinite<ListResponse<TModel>, ListResponse<TModel>>(getKey, fetcher, config);
}
