import Script from 'next/script';
import React from 'react';

export const LibLynxCounter = () => {
  return <Script src="//connect.liblynx.com/log/js/counter5.min.js" async defer />;
};

export const LibLynxCounterInit = () => (
  <Script type="text/javascript" dangerouslySetInnerHTML={{ __html: 'var _ll = _ll || [];' }} />
);
