import { prop } from 'datx';
import { Resource } from 'datx-jsonapi-react';

export class MarketingDocument extends Resource {
  public static type = 'marketing_document_email';

  @prop
  public firstName: string;

  @prop
  public lastName: string;

  @prop
  public recipientEmail: string;

  @prop
  public documentSlug: string;
}
