export const styles = {
  // @ts-ignore
  global: {
    '#__next': {
      position: 'relative',
      zIndex: 0,
    },
    body: {
      fontFamily: 'heading',
      color: 'black',
      bg: 'white',
      transition: 'background-color 0.2s',
      lineHeight: 'base',
      '&.modalVisible': {
        overflow: 'hidden',
      },
    },
    '*::placeholder': {
      color: 'gray.600',
    },
    '*, *::before, &::after': {
      borderColor: 'gray.200',
      wordWrap: 'break-word',
    },
    '#nprogress .bar': {
      background: `linear-gradient(
        90deg,
        #f563ff 0%,
        #0fffe7 25%,
        #08ffff 50.52%,
        #6585f5 77.08%,
        #ef5085 100%
      ),
      linear-gradient(143.26deg, #000000 0.17%, #000000 99.8%)`,
      height: '4px',
    },
    ul: {
      listStyle: 'none',
    },
    'h1,h2,h3,h4,h5,h6': {
      fontWeight: 'bold',
    },
    '& p': {
      my: 4,
    },
  },
};
