import { prop } from 'datx';
import { Resource } from 'datx-jsonapi-react';

export class EventHubCounters extends Resource {
  public static type = 'event_hub_counters';

  @prop
  public eventId: string;

  @prop
  public lecturesCount: number;

  @prop
  public speakersCount: number;
}
