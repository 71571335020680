const text = {
  grotesk: {
    groteskL: {
      fontFamily: 'heading',
      fontSize: { base: '18px', sm: '18px' },
      lineHeight: { base: '22px', sm: '22px' },
    },
    groteskXS: {
      fontFamily: 'heading',
      fontSize: { base: '12px', sm: '12px' },
      lineHeight: { base: '16px', sm: '16px' },
    },
    groteskS: {
      fontFamily: 'heading',
      fontSize: { base: '14px', sm: '14px' },
      lineHeight: { base: '20px', sm: '20px' },
    },
    grotesk2XL: {
      fontFamily: 'heading',
      fontSize: { base: '20px', lg: '24px' },
      lineHeight: { base: '32px', lg: '32px' },
    },
    grotesk3XL: {
      fontFamily: 'heading',
      fontSize: { base: '32px', md: '52px', lg: '62px' },
      lineHeight: { base: '48px', md: '64px', lg: '72px' },
    },
    grotesk4XL: {
      fontFamily: 'heading',
      fontSize: { base: '32px', md: '52px', lg: '68px' },
      lineHeight: { base: '56px', md: '64px', lg: '82px' },
    },
  },
  plex: {
    plexBody: {
      fontFamily: 'body',
      fontSize: { base: '14px', sm: '14px' },
      lineHeight: { base: '20px', sm: '20px' },
    },
    plexH3: {
      fontFamily: 'body',
      fontSize: { base: '32px', sm: '32px' },
      lineHeight: { base: '40px', sm: '40px' },
    },
  },
};

export const textStyles = {
  ...text.grotesk,
  ...text.plex,
};
