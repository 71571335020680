export const layerStyles = {
  blackScrollbar: {
    '&::-webkit-scrollbar': {
      width: '4px',
    },
    '&::-webkit-scrollbar-track': {
      width: '6px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#000',
      borderRadius: '24px',
    },
  },
  blurredStain: {
    _before: {
      position: 'absolute',
      display: 'block',
      content: '""',
      width: '664px',
      height: '635px',
      backgroundColor: '#BFA8F7',
      opacity: 0.5,
      filter: 'blur(67.957px)',
    },
  },
};
