import { prop } from 'datx';

import { BaseModel } from './BaseModel';

export class Banner extends BaseModel {
  public static type = 'banners';

  @prop
  public content: string;

  @prop
  public shownAt: string;

  @prop
  public shownUntil: string;

  @prop
  public type: string;
}
