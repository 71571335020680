import { Response } from 'datx-jsonapi';

import { getRawResponse, Resource } from '@/lib/datx-jsonapi-react';

function getRawData(data: unknown) {
  if (data instanceof Response) {
    return getRawResponse(data).data;
  }

  if (Array.isArray(data)) {
    return data.map((element) => getRawData(element));
  }

  if (data instanceof Resource) {
    return data.toJSON();
  }

  return data;
}

export function datxMiddleware(useSWRNext) {
  return (key, fetcher, config) => {
    const compare = (a, b) => {
      const safeA = getRawData(a);
      const safeB = getRawData(b);

      return config.compare(safeA, safeB);
    };

    const swr = useSWRNext(key, fetcher, { ...config, compare });

    return swr;
  };
}
