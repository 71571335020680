import { css } from '@emotion/react';

export enum ScrollbarVariant {
  LIGHT,
  DARK,
}

export const scrollbarXThemeStyle = (variant: ScrollbarVariant = ScrollbarVariant.DARK) => {
  const color = variant === ScrollbarVariant.DARK ? 'black' : 'white';

  return css`
    // Mozilla
    scrollbar-color: transparent transparent;
    scrollbar-width: thin;

    // Chrome and Safari
    ::-webkit-scrollbar {
      width: 0.2em;
      height: 0.2em;
    }

    ::-webkit-scrollbar-track-piece {
      background-color: transparent;
      -webkit-border-radius: 0px;
    }

    @media (hover: hover) {
      &:hover {
        scrollbar-color: ${color} transparent;

        ::-webkit-scrollbar-track {
          background-color: transparent;
        }
        ::-webkit-scrollbar {
          width: 4px;
          background-color: transparent;
        }
        ::-webkit-scrollbar-thumb {
          background-color: ${color};
        }
      }
    }
  `;
};

export const hideScrollbarXThemeStyle = css`
  ${scrollbarXThemeStyle()}

  @media (hover: hover) {
    &:hover {
      scrollbar-color: transparent transparent;

      ::-webkit-scrollbar-thumb {
        background-color: transparent;
      }
    }
  }
`;

export const scrollbarYThemeStyle = (variant: ScrollbarVariant = ScrollbarVariant.LIGHT) => {
  const color = variant === ScrollbarVariant.DARK ? 'black' : 'white';

  return css`
    // Mozilla
    scrollbar-color: transparent transparent;
    scrollbar-width: thin;

    // Chrome and Safari
    ::-webkit-scrollbar {
      width: 4px;
    }

    ::-webkit-scrollbar-track-piece {
      background-color: transparent;
      -webkit-border-radius: 0px;
    }

    @media (hover: hover) {
      &:hover {
        scrollbar-color: ${color} transparent;

        ::-webkit-scrollbar-track {
          background-color: transparent;
        }
        ::-webkit-scrollbar {
          width: 4px;
          background-color: transparent;
        }
        ::-webkit-scrollbar-thumb {
          background-color: ${color};
        }
      }
    }
  `;
};
