import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
// eslint-disable-next-line no-restricted-syntax
import React from 'react';

import { isServer } from '@/utils/isServer';

Bugsnag.start({
  apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY,
  appVersion: process.env.APP_VERSION,
  appType: isServer() ? 'web_server' : 'client',
  releaseStage: process.env.NEXT_APP_ENV,
  enabledReleaseStages: ['production', 'staging', 'uat'],
  plugins: [new BugsnagPluginReact(React)],
});

export default Bugsnag;
