const parts = ['root', 'image', 'body'];

const variants = {
  default: {
    root: {
      flexDirection: { base: 'column', lg: 'row' },
    },
    image: {
      flexDirection: { base: 'column', lg: 'row' },
      alignItems: 'center',
    },
    body: {
      borderLeft: { base: 'none', lg: '1px' },
      borderColor: { lg: 'gray.200' },
    },
  },
};
const sizes = {
  lg: {
    image: {
      mr: { base: 0, lg: 8 },
      w: { base: '159px', sm: '283px' },
      maxW: '100%',
      maxH: '100%',
    },
    body: {
      px: { base: 0, md: 24 },
    },
  },
};

const baseStyle = {
  root: {
    display: 'flex',
  },
  image: {
    display: 'flex',
  },
  body: {
    display: 'flex',
  },
};

const defaultProps = {
  variant: 'default',
  size: 'lg',
};

export default {
  variants,
  parts,
  sizes,
  baseStyle,
  defaultProps,
};
