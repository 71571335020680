import { prop } from 'datx';

import { BaseModel } from './BaseModel';

export class Chapter extends BaseModel {
  public static type = 'chapters';

  @prop
  public title: string;

  @prop
  public timestamp: number;
}
