import { MobXProviderContext } from 'mobx-react';
import { useContext } from 'react';

import { AppCollection as AppStore } from '@/stores/AppStore';

interface IUseStore {
  store: AppStore;
}

export function useStores(): IUseStore {
  return useContext(MobXProviderContext) as IUseStore;
}
