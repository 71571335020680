export default {
  titleTemplate: 'Underline | %s',
  title: 'Watch lectures from the best researchers.',
  description: 'On-demand video platform giving you access to lectures from conferences worldwide.',
  openGraph: {
    url: process.env.NEXT_PUBLIC_BASE_URL,
    title: 'Watch lectures from the best researchers.',
    description: 'On-demand video platform giving you access to lectures from conferences worldwide.',
    images: [
      {
        url: `${process.env.NEXT_PUBLIC_BASE_URL}images/landing/hero-share.jpg`,
        width: 818,
        height: 520,
        alt: 'Underline.io',
      },
    ],
    type: 'website',
    site_name: 'Underline.io',
  },
  facebook: {
    appId: '',
  },
  twitter: {
    handle: '@handle',
    site: '@site',
    cardType: 'summary_large_image',
  },
};
