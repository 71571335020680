/**
 * @deprecated - use chakra ui theme colors
 */
export const legacyColors = {
  white: '#FFF',
  black: '#000',
  gray: '#C4C4C4',
  gray50: '#808080',
  gray80: '#CCCCCC',
  blueLink: '#0788FF',
  lightGray: '#F0F0F0',
  pageBackground: '#F2F2F2',
  lightText: '#808080',
  defaultText: '#000000',
  brand: {
    primary: '#5B1CF4',
    primaryPale: 'rgba(92, 26, 244, 0.1)',
    secondary: '#B6FFE2',
    signalGreenLightFaded: '#B3D8D2',
    signalGreenLight: '#19A68F',
    signalGreenDarkFaded: '#6F8585',
    glassGreenDark: 'rgba(42, 95, 99, 0.3)',
    signalRed: '#EF5085',
    alert: '#FF3030',
  },
  status: {
    error: '#FF0303',
    warning: '#F2E01D',
    info: '#38F3FF',
    success: '#1EE6A0',
  },
  skeleton: {
    defaultStartColor: 'rgba(216,216,216,0.27)',
    defaultEndColor: 'rgba(216,216,216,0.20)',
  },
  overlayTransparentBackground: 'rgba(0, 0, 0, 0.5)',
  modalTransparentBackground: 'rgba(173, 216, 208, 0.27)',
  modalFilterFallback: '#5D6D6F',
  transcriptCurrentOverlay: '#fff',
  transcriptHoverOverlay: '#0C3E3B',
  blackActive: '#3F3F3F',
};

export const colors = {
  gray: {
    50: '#F3F5F5',
    100: '#E3E5E5',
    200: '#CACCCC',
    300: '#B1B2B2',
    400: '#969999',
    500: '#7D8080',
    600: '#646666',
    700: '#808080',
    800: '#313333',
    900: '#181919',
  },
  violet: {
    50: '#EFE8FE',
    100: '#D6C6FC',
    200: '#AA8AF7',
    300: '#8455F7',
    400: '#6B33F5',
    500: '#5B1CF4',
    600: '#4916C3',
    700: '#371192',
    800: '#240B62',
    900: '#1B0849',
  },
  teal: {
    50: '#E2FFF3',
    100: '#C5FFE8',
    200: '#B6FFE2',
    300: '#82E1C6',
    400: '#4DC4AB',
    500: '#19A68F',
    600: '#158A77',
    700: '#0F6557',
    800: '#0D4F44',
    900: '#24332D',
  },
  blue: {
    50: '#E6F3FF',
    100: '#B5DBFF',
    200: '#73C4FF',
    300: '#51ACFF',
    400: '#39A0FF',
    500: '#0788FF',
    600: '#066DCC',
    700: '#045299',
    800: '#033666',
    900: '#011B33',
  },
  pink: {
    50: '#FDECFF',
    100: '#FCD9FF',
    200: '#FABFFF',
    300: '#F89FFF',
    400: '#F576FF',
    500: '#EF22FF',
    600: '#DE20EC',
    700: '#CA1DD8',
    800: '#B41AC0',
    900: '#9916A3',
  },
  almostRed: {
    50: '#FDEDF3',
    100: '#FCDBE6',
    200: '#FAC3D5',
    300: '#F7A7C2',
    400: '#F484A9',
    500: '#EF5085',
    600: '#DE4A7B',
    700: '#CA4471',
    800: '#B43C64',
    900: '#9A3456',
  },
  red: {
    50: '#FFEFEF',
    100: '#FFDEDE',
    200: '#FFCACA',
    300: '#FFB3B3',
    400: '#FF9797',
    500: '#FF7373',
    600: '#FF3030',
    700: '#E82C2C',
    800: '#CD2727',
    900: '#AD2020',
  },
  yellow: {
    50: '#FEF9CB',
    100: '#FDF498',
    200: '#FCF284',
    300: '#FCF06E',
    400: '#FCEE56',
    500: '#FBEB3D',
    600: '#E1D337',
    700: '#C2B62F',
    800: '#9B9226',
    900: '#615B18',
  },
  cyan: {
    50: '#DFFDFF',
    100: '#BFFBFF',
    200: '#B0FAFF',
    300: '#9EF9FF',
    400: '#8BF8FF',
    500: '#74F7FF',
    600: '#38F3FF',
    700: '#38F3FF',
    800: '#2CBDC6',
    900: '#176367',
  },
  green: {
    50: '#DEFDF3',
    100: '#BDFBE6',
    200: '#A5FADC',
    300: '#89F8D1',
    400: '#67F7C4',
    500: '#3AF4B3',
    600: '#32D099',
    700: '#2DBE8C',
    800: '#259A71',
    900: '#18654A',
  },
  gradientNavigation: 'linear-gradient(to bottom, #000000 0%, rgba(0, 0, 0, 0) 100%)',
  gradientBackground: `radial-gradient(
      98.44% 95.14% at -0.8% 0%,
      #f563ff 0%,
      rgba(8, 255, 255, 0.5) 10.76%,
      rgba(15, 255, 231, 0.35) 30%,
      rgba(255, 120, 147, 0.1) 55%,
      rgba(0, 5, 15, 0) 86%
    ), #000000`,
  gradientBackground2: `radial-gradient(
    111.42% 206.22% at -0.8% 0%,
    #f563ff 0%,
    rgba(8, 255, 255, 0.5) 10.76%,
    rgba(15, 255, 231, 0.35) 30%,
    rgba(255, 120, 147, 0.1) 55%,
    rgba(0, 5, 15, 0) 86%
  ), #000000`,
  gradientBanners: {
    violet: `linear-gradient(
      180deg,
      rgba(170, 138, 247, 0.75) 0%,
      rgba(170, 138, 247, 0) 100%
    )`,
  },
  buttonDisabledLight: 'rgba(216,216,216,0.27)',
  dropdownAlpha: 'rgba(24, 25, 25, 0.8)',
};
