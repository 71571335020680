import { prop } from 'datx';

import { Resource } from '@/lib/datx-jsonapi-react';

export class LectureAccessPermissions extends Resource {
  public static type = 'lecture_access_permissions';

  @prop
  public restricted: boolean;
}
