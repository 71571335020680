import { prop } from 'datx';
import { Resource } from 'datx-jsonapi-react';

export class LiblynxAuthorizationGrants extends Resource {
  public static type = 'liblynx_authorization_grants';

  @prop
  public status: string;

  @prop
  public liblynxOrganization: string;

  @prop
  public liblynxAccountId: number;
}
