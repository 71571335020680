import { buttonTheme } from './button';
import { fontFamilies } from './font-families';
import { legacyColors } from './foundations/colors';
import { legacyRadii } from './foundations/radius';
import { scrollbarXThemeStyle, scrollbarYThemeStyle, hideScrollbarXThemeStyle } from './scrollbar';

export enum Breakpoint {
  Unknown = 'Unknown',
  Mobile = 'Mobile',
  Tablet = 'Tablet',
  Desktop = 'Desktop',
}

export interface IRange {
  min: number;
  max: number;
}

export type Breakpoints = Record<Breakpoint, IRange>;

// Value defined with designers (Rade & Kata)
export const MAX_CONTENT_WIDTH = 1200;

// Values defined with designer (Marina)
export const BREAKPOINTS: Breakpoints = {
  Unknown: {
    min: 0,
    max: 0,
  },
  Mobile: {
    min: 1,
    max: 767,
  },
  Tablet: {
    min: 768,
    max: 1023,
  },
  Desktop: {
    min: 1024,
    max: Number.MAX_SAFE_INTEGER,
  },
};

const breakpoints: Array<string> & Partial<Breakpoints> = ['640px', '768px', '1024px', '1280px'];

breakpoints.Unknown = BREAKPOINTS.Unknown;
breakpoints.Mobile = BREAKPOINTS.Mobile;
breakpoints.Tablet = BREAKPOINTS.Tablet;
breakpoints.Desktop = BREAKPOINTS.Desktop;

/**
 * @deprecated replaced with Chakra UI theme. Do not import this anywhere directly, use the theme form default.ts!
 */
export const theme = {
  breakpoints,
  typography: {
    fontFamily: fontFamilies,
  },
  colors: legacyColors,
  gradient: {
    darkGreenHorizontal: 'linear-gradient(107.02deg, #113639 28.13%, #071618 75.78%, #000000 89.53%)',
    darkGreenVertical:
      // eslint-disable-next-line max-len
      'radial-gradient(2483.16% 100% at 33.72% 100%, rgba(86, 232, 236, 0.46) 0%, rgba(86, 236, 227, 0.3) 33.33%, rgba(237, 194, 201, 0.15) 60.94%, rgba(0, 0, 0, 0.15) 100%);',
    subjectsBackground: 'linear-gradient(0deg, rgba(0, 255, 224, 0.05), rgba(0, 255, 224, 0.05)), rgba(0, 0, 0, 0.4);',
    headerDarkRadial:
      'radial-gradient(54.31% 103.55% at 14.13% 100%, rgba(0, 0, 0, 0.8) 50.5%, rgba(0, 0, 0, 0) 100%);',
    headerMobile:
      'linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 24%,rgba(0,0,0,0.65) 37%,rgba(0, 0, 0, 0.95) 70%);',
    fadeText: 'linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(242,242,242,1) 71%,rgba(242,242,242,1) 72%);',
    fadeTextAlternative: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%, #FFFFFF 100%);',
  },
  shadow: {
    boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.1)',
    tooltipShadow: '0px 3px 8px rgba(0, 0, 0, 0.2)',
  },
  button: buttonTheme,
  scrollbarXStyle: scrollbarXThemeStyle,
  scrollbarYStyle: scrollbarYThemeStyle,
  hideScrollbarXStyle: hideScrollbarXThemeStyle,
  textOverflowOneLine: 'overflow: hidden; white-space: nowrap; text-overflow: ellipsis;',
  textOverflowBreakLine: 'overflow: hidden; white-space: pre-wrap; text-overflow: ellipsis;',
  radii: legacyRadii,
};

export type Theme = typeof theme;
