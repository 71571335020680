import { prop } from 'datx';

import { BaseModel } from './BaseModel';

export class Country extends BaseModel {
  public static type = 'countries';

  @prop
  public name: string;

  @prop
  public code: string;
}
