import { prop } from 'datx';
import { Resource } from 'datx-jsonapi-react';

export class ConferenceHubCounters extends Resource {
  public static type = 'conference_hub_counters';

  @prop
  public conferenceId: string;

  @prop
  public lecturesCount: number;

  @prop
  public visibleLibraryEventsCount: number;
}
