import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import { NextRouter } from 'next/router';
import { parse, UrlObject } from 'url';

import { IdpEntityIds } from '@/enums/IdpEntityIds';

export function getSamlSignInUrl(idpEntityId: IdpEntityIds, redirectTo: string) {
  const queryParams = new URLSearchParams({
    idp_entity_id: idpEntityId,
    redirect_to: redirectTo,
  });

  return `${process.env.NEXT_PUBLIC_SAML_SIGN_IN_URL}?${queryParams.toString()}`;
}

/*
 * Kinda obsolete because of this https://nextjs.org/docs/api-reference/next/link#with-url-object
 *
 * as - Optional decorator for the path that will be shown in the browser URL bar.
 * Before Next.js 9.5.3 this was used for dynamic routes, check our previous docs to see how it worked
 */
export function updateNextUrlQueryParams(router: NextRouter, queryParams: Record<string, any>) {
  const asPathParsed = parse(router.asPath, true);

  const url: UrlObject = {
    pathname: router.pathname,
    query: omitBy(
      {
        ...router.query,
        ...queryParams,
      },
      isNil
    ),
  };

  const as: UrlObject = {
    pathname: asPathParsed.pathname,
    query: omitBy({ ...asPathParsed.query, ...queryParams }, isNil),
  };

  return {
    url,
    as,
  };
}
