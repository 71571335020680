import { mode } from '@chakra-ui/theme-tools';

type Dict = Record<string, any>;

function baseStyle(props: Dict) {
  return {
    color: mode(`${props.colorScheme}.500`, `${props.colorScheme}.200`)(props),
  };
}

const defaultProps = {
  size: 'md',
  colorScheme: 'violet',
};

export default {
  baseStyle,
  sizes: {
    lg: {
      w: '80px',
      h: '80px',
    },
    md: {
      w: '40px',
      h: '40px',
    },
    sm: {
      w: '24px',
      h: '24px',
    },
  },
  defaultProps,
};
