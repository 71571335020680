import { prop } from 'datx';
import { Resource } from 'datx-jsonapi-react';

export type TagVariant = 'primary' | 'secondary' | 'tertiary' | 'quaternary' | 'quinary';

export class Tag extends Resource {
  public static type = 'tags';

  @prop
  public name: string;

  @prop
  public variant: TagVariant;
}
