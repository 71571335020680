import { prop } from 'datx';
import { Resource } from 'datx-jsonapi-react';

import { EventChatChannel } from '@/resources/EventChatChannel';
import { User } from '@/resources/User';

export class EventChatChannelParticipant extends Resource {
  public static type = 'event_chat_channel_participants';

  @prop.toOne('event_chat_channels')
  public eventChatChannel: EventChatChannel;

  @prop.toOne('users')
  public user: User;
}
