import { useConst } from '@chakra-ui/react';
import { createContext } from '@chakra-ui/react-utils';
import { AnalyticsBrowser } from '@segment/analytics-next';

const [AnalyticsContextProvider, useAnalytics] = createContext<AnalyticsBrowser>({
  strict: true,
  name: 'AnalyticsContext',
  errorMessage:
    'AnalyticsContext: `context` is undefined. Seems you forgot to wrap modal components in `<AnalyticsProvider />`',
});

const AnalyticsProvider = ({ children, writeKey }) => {
  const analytics = useConst(() => AnalyticsBrowser.load({ writeKey }));

  return <AnalyticsContextProvider value={analytics}>{children}</AnalyticsContextProvider>;
};

export { AnalyticsProvider, useAnalytics };
