function baseStyle() {
  return {
    pos: 'fixed',
    top: '0',
    left: '0',
    bottom: '0',
    right: '0',
    bgColor: 'black',
    opacity: 0.5,
  };
}

export default {
  baseStyle,
};
