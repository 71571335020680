import { EventListStatus } from '@/enums/EventListStatus';

import { splitIdAndSlug } from './helperFunctions';

export const StringParam = (value: string | Array<string> | null | undefined) => {
  return Array.isArray(value) ? value[0] : value;
};

export const StringArrayParams = (value: string | Array<string> | null | undefined) => {
  if (!value) return null;
  if (typeof value === 'string' && value.includes(',')) return value.split(',').map((item) => decodeURIComponent(item));

  return [decodeURIComponent(value as string)];
};

export const IntParam = (value: string | Array<string> | null | undefined) => {
  return Array.isArray(value) ? parseInt(decodeURIComponent(value[0]), 10) : parseInt(decodeURIComponent(value), 10);
};

export const ArrayLinkSlugParam = (value: string | Array<string> | null | undefined) => {
  return Array.isArray(value) ? value.map((linkSlug: string) => splitIdAndSlug(linkSlug)) : [splitIdAndSlug(value)];
};

export const SlugIdParam = (value: string | Array<string> | null | undefined) => {
  return Array.isArray(value) ? splitIdAndSlug(value[0]).id : splitIdAndSlug(value).id;
};

export type ParamType = typeof StringParam | typeof ArrayLinkSlugParam | typeof IntParam | typeof SlugIdParam;
export type ParamTypes = Record<string, ParamType>;
// TODO make this type work with string and Array<string> if there's no transformFn
export type ParamReturnType<Type extends Record<string, (...args: any) => any>> = {
  [Property in keyof Type]: ReturnType<Type[Property]>;
};

export const StatusStringParam = (value: EventListStatus | Array<EventListStatus> | null | undefined) => {
  const param = StringParam(value);

  const validValues: Array<string> = Object.keys(EventListStatus)
    .map((key) => EventListStatus[key])
    .filter((k) => !(parseInt(k, 10) >= 0));

  if (validValues.includes(param)) {
    return param;
  }

  return undefined;
};

export function urlQueryStringValidate<T>(query: Record<string, string | Array<string>>, paramTypes: ParamTypes): T {
  const validatedQuery: any = {};

  Object.keys(query).forEach((key) => {
    const transformFn = paramTypes[key];

    if (transformFn) {
      validatedQuery[key] = transformFn?.(query[key]);
    } else {
      validatedQuery[key] = query[key];
    }
  });

  return validatedQuery;
}
